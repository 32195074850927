export const NUMBER_OF_TEAMS_PER_GAME = 3
export const MIN_NUMBER_OF_TEAMS_PER_GAME = 2

/**
 * Approximate number of team for the round with index
 *
 * @param gamesTotal
 * @param index
 * @param numberOfWinners
 * @param numberOfLosers
 * @return {number}
 */
export function approximate({
  gamesTotal,
  index,
  numberOfWinners,
  numberOfLosers
}) {
  const progressInPercentage = (100 / gamesTotal) * (index + 1)
  const reversedProgressInPrecentage = 100 - progressInPercentage
  const numberOfWinnersAndLosers = numberOfWinners + numberOfLosers
  // get percent of all available teams that satisfies the remainig
  // rounds and linear progression throught them
  const numberOfTeamsPreferred =
    Math.floor(
      (numberOfWinnersAndLosers / 100) * reversedProgressInPrecentage
    ) + NUMBER_OF_TEAMS_PER_GAME

  const numberOfTeams = Math.max(numberOfWinners, numberOfTeamsPreferred)

  return (
    Math.ceil(numberOfTeams / NUMBER_OF_TEAMS_PER_GAME) *
    NUMBER_OF_TEAMS_PER_GAME
  )
}

/**
 *
 * @param index
 * @param gamesTotal
 * @param numberOfWinners
 * @param numberOfLosers
 * @return {{numberOfGames: number, numberOfTeams: number}}
 */
export function approximateRound(
  index,
  gamesTotal,
  numberOfWinners,
  numberOfLosers
) {
  const isLastGame = index + 1 === gamesTotal

  // compute the number of teams with respect to the number of rounds
  // and the current round
  const numberOfTeams = isLastGame
    ? Math.max(numberOfWinners, NUMBER_OF_TEAMS_PER_GAME)
    : approximate({ gamesTotal, index, numberOfWinners, numberOfLosers })

  const numberOfGames = isLastGame
    ? 1
    : Math.ceil(numberOfTeams / NUMBER_OF_TEAMS_PER_GAME)

  return { numberOfGames, numberOfTeams }
}

export function approximateTournament(
  gamesTotal,
  numberOfPlayers,
  playersPerTeam
) {
  let numberOfTeams = Math.ceil(numberOfPlayers / playersPerTeam)
  let numberOfGames = Math.ceil(numberOfTeams / playersPerTeam)

  const array = []

  for (let i = 0; i < gamesTotal; i++) {
    const object = approximateRound(
      i,
      gamesTotal,
      numberOfGames, // winners
      numberOfTeams - numberOfGames // losers
    )
    numberOfGames = object.numberOfGames
    numberOfTeams = object.numberOfTeams
    array.push({
      round: i + 1,
      numberOfGames,
      numberOfTeams,
      description: `Round: ${i +
        1}   Games: ${numberOfGames}   Teams: ${numberOfTeams}`
    })
  }

  return array
}
