<template>
  <v-flex d-flex class="client-setting-rr">
    <v-layout column>
      <v-flex shrink pa-4>
        <v-layout row>
          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'GAMES'"
            class="rr-client-settings-tab"
            :class="tab == 'GAMES' ? 'selected' : null"
          >
            GAMES
          </v-flex>
          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'TOURNAMENT'"
            class="rr-client-settings-tab"
            :class="tab == 'TOURNAMENT' ? 'selected' : null"
          >
            TOURNAMENT
          </v-flex>
          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'EDIT'"
            class="rr-client-settings-tab"
            :class="tab == 'EDIT' ? 'selected' : null"
          >
            EDIT
          </v-flex>

          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'ATTENDANCE'"
            class="rr-client-settings-tab"
            :class="tab == 'ATTENDANCE' ? 'selected' : null"
          >
            ATTENDANCE
          </v-flex>
          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'STYLE'"
            class="rr-client-settings-tab"
            :class="tab == 'STYLE' ? 'selected' : null"
            >STYLE</v-flex
          >
          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'IMAGES'"
            class="rr-client-settings-tab"
            :class="tab == 'IMAGES' ? 'selected' : null"
            >IMAGES
          </v-flex>
          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'MATCH_EMAILS'"
            class="rr-client-settings-tab"
            :class="tab == 'MATCH_EMAILS' ? 'selected' : null"
          >
            MATCH EMAILS
          </v-flex>
          <v-flex
            shrink
            pl-2
            pr-2
            @click="tab = 'ACTIONS'"
            class="rr-client-settings-tab"
            :class="tab == 'ACTIONS' ? 'selected' : null"
          >
            ACTIONS
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex pa-4 d-flex class="scrollable" v-if="tab == 'GAMES'">
        <v-flex xs12 v-if="!!object">
          <v-layout style="margin-top: -20px" column>
            <v-tabs v-model="currentRound">
              <v-tab @click="currentRound = -1"> All</v-tab>
              <v-tab v-for="i in numOfRounds" :key="i">
                Round {{ i }}
              </v-tab> </v-tabs
            ><br />
            <v-layout row wrap>
              <v-btn small @click="stage()"> Stage </v-btn>
              <v-btn small @click="unstage()"> Un Stage </v-btn>
              <v-btn small @click="activate()"> On </v-btn>
              <v-btn small @click="deactivate()"> Off </v-btn>
              <v-btn small @click="changeMinutes(-5)"> - 5 </v-btn>
              <v-btn small @click="changeMinutes(-1)"> - 1 </v-btn>
              <v-btn small @click="startNow()"> Start Now </v-btn>
              <v-btn small @click="changeMinutes(1)"> + 1 </v-btn>
              <v-btn small @click="changeMinutes(5)"> + 5 </v-btn>
              <v-flex sm1 mx-2>
                <datepicker
                  style="margin-top: 5px; width: 100px"
                  v-model="startDate"
                  :placeholder="'Game Date'"
                />
              </v-flex>
              <v-flex sm1 mx-2>
                <vue-timepicker v-model="startTime" format="HH:mm:ss" />
              </v-flex>
              <v-btn small @click="setStartTime()"> Set Start </v-btn>
              <v-flex sm1 mx-2>
                <datepicker
                  style="margin-top: 5px; width: 100px"
                  v-model="endDate"
                  :placeholder="'End Game Date'"
                />
              </v-flex>
              <v-flex sm1 mx-2>
                <vue-timepicker v-model="endTime" format="HH:mm:ss" />
              </v-flex>
              <v-btn small @click="setEndTime()"> Set Master End </v-btn>
              <br /><br /><br />
            </v-layout>
            <v-flex
              shrink
              v-for="(game, i) in filteredGamesArray"
              :key="`client-game-${game.theKey}`"
            >
              <v-layout row>
                <v-flex d-flex style="margin-top: -15px">
                  <ClientSettingGame
                    :position="i + 1"
                    :game="game"
                    :users="hosts"
                    :initializing="initializing"
                    @update="onGameUpdate"
                    @changeDefaultKeynote="changeDefaultKeynote"
                    @onSelect="
                      onGameSelect({
                        gameID: game.theKey,
                        clientID: game.clientID
                      })
                    "
                  />
                </v-flex>
                <v-flex shrink ml-3 style="margin-top: -18px">
                  <v-layout column>
                    <v-flex>
                      <a
                        :href="`/gamelogin/${game.clientID}${game.theKey}?auth=0`"
                        target="_blank"
                      >
                        Login
                      </a>
                    </v-flex>
                    <v-flex>
                      <a
                        :href="`/gamelogin/${game.clientID}${game.theKey}?audit=1&auth=0`"
                        target="_blank"
                      >
                        Audit
                      </a>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs4 mx-2>
              <v-select
                v-model="newTournamentGameID"
                :items="masterGames"
                label="Copy Master Game To Event"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex xs1 v-if="!!newTournamentGameID">
              <v-btn small mt-3 icon @click="addGame()">
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs4 mx-2>
              <v-select
                v-model="newTournamentGameID"
                :items="userGames"
                label="Copy User Game To Event"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex xs1 v-if="!!newTournamentGameID">
              <v-btn small mt-3 icon @click="addGame()">
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout column>
            <v-flex shrink>
              <v-layout justify-center>
                <v-btn flat class="secondary" @click="cancel"
                  >Back To Game</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-flex>
      <v-flex pa-4 class="scrollable" v-if="tab == 'TOURNAMENT'">
        <TournamentBuilder :clientID="clientID" :orgID="orgID" />
        <TournamentControls class="mt-3" />
      </v-flex>
      <v-flex pa-4 d-flex class="scrollable" v-if="tab == 'EDIT'">
        <v-layout column>
          <v-flex>
            <v-layout row wrap>
              <v-flex style="margin-top: -20px; margin-bottom: 20px" xs12 mx-2>
                <b
                  >Game Link:
                  <a
                    :href="`https://remoteteambuilding.com/login/${clientID}?auth=0`"
                    >https://remoteteambuilding.com/login/{{
                      clientID
                    }}?auth=0</a
                  ></b
                >
              </v-flex>
              <v-flex xs8 mx-2>
                <v-text-field v-model="object.name" label="Name" required />
              </v-flex>
              <v-flex xs3 mx-2>
                <v-select
                  v-model="object.restriction"
                  :items="restrictions"
                  label="Game Assignment Type"
                  item-text="text"
                  item-value="code"
                />
              </v-flex>
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex d-flex align-center xs12 pr-4>
                    <v-text-field
                      v-model="object.description"
                      label="NOTES"
                      required
                    />
                  </v-flex>
                  <v-flex d-flex align-center xs2>
                    <v-switch
                      v-model="object.locked"
                      label="Locked (WILL REMOVE EVERYONE)"
                    />
                  </v-flex>
                  <v-flex d-flex align-center xs2>
                    <v-switch v-model="object.debug" label="DEBUG" />
                  </v-flex>
                  <v-flex d-flex align-center xs2>
                    <v-switch v-model="object.noUserPhoto" label="Use Avatar" />
                  </v-flex>
                  <v-flex d-flex align-center xs2>
                    <v-switch
                      v-model="client.onboardChat"
                      label="Onboard Chat"
                    />
                  </v-flex>
                  <v-flex d-flex align-center xs2>
                    <v-switch
                      v-model="object.anonymousUsers"
                      label="Hide Hosts"
                    />
                  </v-flex>
                  <v-flex style="margin-top: -15px" d-flex align-center xs2>
                    <v-switch
                      v-model="object.noExpoTour"
                      label="No Expo Tour"
                    />
                  </v-flex>
                  <v-flex d-flex align-center xs2>
                    <v-switch
                      v-model="object.regularLogin"
                      label="Email Login"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 v-if="!object.regularLogin">
                <form @submit.prevent="() => {}" autocomplete="off">
                  <v-text-field
                    autocomplete="new-password"
                    v-model="password"
                    placeholder=" "
                    :append-icon="
                      showPassword ? 'visibility' : 'visibility_off'
                    "
                    :type="showPassword ? 'text' : 'password'"
                    label="Optional Password"
                    @click:append="showPassword = !showPassword"
                  />
                </form>
              </v-flex>

              <v-flex sm7 mx-2>
                <v-text-field
                  v-model="object.announcement"
                  label="Annoucement"
                  required
                />
              </v-flex>
              <v-flex sm3 mx-2>
                <v-text-field
                  v-model="object.returnUrl"
                  label="Return Url"
                  required
                />
              </v-flex>
              <v-flex xs1>
                <v-text-field
                  v-model.number="object.maxCapacity"
                  label="Max players capacity"
                />
              </v-flex>
              Front login page<br />
              <v-flex d-flex align-center xs2>
                <v-switch v-model="object.hideGoGame" label="Hide GoGame" />
              </v-flex>
              <v-flex xs2 mx-2>
                <v-text-field v-model="object.tagline" label="Tagline" />
              </v-flex>
              <v-flex xs2 mx-2>
                <v-text-field
                  v-model="object.taglineColor"
                  label="Tagline Hex Color"
                />
              </v-flex>
              <v-flex xs2 mx-2>
                <v-text-field
                  v-model="object.latecomerVideoCode"
                  label="Later Comer You Tube Code "
                />
              </v-flex>
              <v-flex sm3 mx-2>
                <v-text-field
                  v-model="object.redirectURL"
                  label="Redirect URL"
                  required
                />
              </v-flex>
              <v-flex d-flex xs12 mx-2>
                <v-layout row>
                  <v-flex xs3>
                    <v-select
                      v-model="object.customInputType"
                      :items="customInputTypes"
                      label="CUSTOM INPUT"
                      item-text="label"
                      item-value="value"
                    />
                  </v-flex>
                  <v-flex xs3 v-if="!!object.customInputType">
                    <v-text-field
                      label="Custom Input Label"
                      v-model="object.customInputLabel"
                    />
                  </v-flex>
                  <v-flex v-if="object.customInputType === 'select'">
                    <v-textarea
                      label="Custom Input Options (comma separated)"
                      v-model="object.customInputOptions"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink>
            <v-layout justify-center>
              <v-btn
                :loading="loadingImage"
                flat
                class="primary"
                @click="update"
              >
                Save
              </v-btn>
              <v-btn flat class="secondary" @click="cancel">Back</v-btn>
              <v-btn flat class="secondary" @click="purge">Purge</v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex pa-4 d-flex class="scrollable" v-else-if="tab == 'ATTENDANCE'">
        <v-layout column>
          <table>
            <thead>
              <tr>
                <th colspan="5" class="table-header--header">
                  <b>Total attendance:</b> {{ attendanceNum }}
                </th>
                <th colspan="4" class="table-header--buttons">
                  <v-btn flat small class="primary" @click="exportCSV"
                    >Export to CSV</v-btn
                  >
                  <v-btn flat small class="secondary" @click="fetchAllEmails"
                    >Show All Emails</v-btn
                  >
                </th>
              </tr>
            </thead>
            <tr>
              <td>FIRSTNAME</td>
              <td>LASTNAME</td>
              <td>IDENTIFIER</td>
              <td>GAME</td>
              <td>TEAM</td>
              <td>SCORE</td>
              <td>FIRST SEEN</td>
              <td>LAST SEEN</td>
              <td>EMAIL</td>
            </tr>
            <tr></tr>
            <tr
              v-for="(user, key) in attendenceWithEmail"
              :key="`attendance-${user.id}-${key}`"
            >
              <td>{{ user.firstname }}</td>
              <td>{{ user.lastname }}</td>
              <td>{{ user.identifier }}</td>
              <td>{{ user.gameName }}</td>
              <td>{{ user.teamName }}</td>
              <td>{{ user.teamScore }}</td>
              <td>{{ user.firstSeen }}</td>
              <td>{{ user.lastSeen }}</td>
              <td
                class="user-email"
                v-if="!!user.email && user.email !== 'none'"
                xs3
              >
                {{ user.email }}
              </td>
              <td
                v-else-if="user.email !== 'none'"
                @click="fetchEmail({ userID: user.id })"
                xs3
                class="email-link"
              >
                SHOW
              </td>
            </tr>
          </table>
        </v-layout>
      </v-flex>
      <v-flex pa-4 d-flex class="scrollable" v-else-if="tab == 'STYLE'">
        <ClientSettingsStylesTabpanel
          :client="object"
          @input:theme="onThemeInput"
          @save="update"
        />
      </v-flex>
      <v-flex v-else-if="tab == 'IMAGES'">
        <v-layout row wrap class="pa-2">
          <v-flex xs5 mx-2>
            Top Left Image
            <img width="150" :src="object.logoImage" />
            <image-uploader
              :debug="1"
              :max-width="1200"
              :quality="1"
              :auto-rotate="true"
              :preview="false"
              :class-name="[
                'fileinput',
                { 'fileinput--loaded': object.logoImage }
              ]"
              output-format="verbose"
              capture="environment"
              accept=".jpg,.png,.jpeg"
              @input="setImage($event, client)"
            >
            </image-uploader>
            <v-text-field v-model="object.logoImage" label="Top Left Photo" />
          </v-flex>
          <v-flex xs5 mx-2>
            <v-switch v-model="object.hideExpoText" label="Hide Expo Text" />
          </v-flex>
          <v-flex xs5 mx-2>
            Expo Custom Logo
            <img width="150" :src="object.customLogo" />
            <image-uploader
              :debug="1"
              :max-width="1200"
              :quality="1"
              :auto-rotate="true"
              :preview="false"
              :class-name="[
                'fileinput',
                { 'fileinput--loaded': object.customLogo }
              ]"
              output-format="verbose"
              capture="environment"
              accept=".jpg,.png,.jpeg"
              @input="setCustomImage($event, client)"
            >
            </image-uploader>
            <v-text-field
              v-model="object.customLogo"
              label="Expo Custom Logo"
            />
          </v-flex>
          <v-flex xs3 mx-2>
            Expo Custom Logo Style
            <v-text-field
              v-model="object.customLogoStyle"
              label="Expo Custom Logo Style"
            />
          </v-flex>
          <v-flex xs5>
            <span class="caption">Login logo</span>
            <img
              v-if="!!object.loginLogo"
              :src="object.loginLogo"
              alt="Login logo"
              class="rtb-max-w-full"
            />
            <image-uploader
              :debug="1"
              :max-width="MAX_LOGIN_LOGO_WIDTH"
              auto-rotate
              :preview="false"
              :class-name="[
                'fileinput',
                { 'fileinput--loaded': object.loginLogo }
              ]"
              output-format="verbose"
              capture="environment"
              accept=".jpg, .png, .jpeg"
              @input="setLoginLogo($event, client)"
            ></image-uploader>
            <v-text-field
              v-if="!!object.loginLogo"
              v-model="object.loginLogo"
              disabled
              label="Login logo URL"
              hide-details
              class="mt-4"
            />
            <v-btn
              v-if="!!object.loginLogo"
              color="error"
              depressed
              small
              class="ml-0"
              @click="deleteLoginLogo"
              >Delete</v-btn
            >
          </v-flex>
          <v-flex v-for="(image, index) in images" :key="index" xs2>
            LOGIN PAGE IMAGE
            <img v-if="image" :src="image" alt="Image" class="rtb-max-w-full" />
            <image-uploader
              :debug="1"
              :max-width="CLIENT_IMAGES_MAX_WIDTH"
              auto-rotate
              :preview="false"
              :class-name="['fileinput', { 'fileinput--loaded': image }]"
              output-format="verbose"
              capture="environment"
              accept=".jpg, .png, .jpeg"
              @input="updateImages($event, index)"
            ></image-uploader>
            <v-btn
              v-if="image"
              color="error"
              depressed
              small
              class="ml-0"
              @click="imagesDeleteHandler(index)"
              >Delete</v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout justify-center>
          <v-btn :loading="loadingImage" flat class="primary" @click="update"
            >Save</v-btn
          >
          <v-btn flat class="secondary" @click="cancel">Cancel</v-btn>
        </v-layout>
      </v-flex>
      <v-flex v-if="tab == 'MATCH_EMAILS'" pa-4 d-flex class="scrollable">
        <v-layout column>
          <v-flex d-flex>
            <ClientSettingMatchEmails v-model="object.matchEmails" />
          </v-flex>
          <v-flex shrink>
            <v-layout justify-center>
              <v-btn
                :loading="loadingImage"
                flat
                class="primary"
                @click="update"
              >
                Save
              </v-btn>
              <v-btn flat class="secondary" @click="cancel">Cancel</v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="tab == 'ACTIONS'" key="actions">
        <ClientSettingActionsTabpanel />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"
import uniqid from "uniqid"
import ClientSettingGame from "./ClientSettingGame"
import ClientSettingMatchEmails from "./ClientSettingMatchEmails"
import TournamentControls from "@/components/Tournament/TournamentControls"
import TournamentBuilder from "@/components/Tournament/TournamentBuilder"
import ClientSettingsStylesTabpanel from "./ClientSettingsStylesTabpanel.vue"
import ClientSettingActionsTabpanel from "@/components/Game/ClientSettingsActionsTabpanel.vue"
import firebase from "firebase/app"
import * as moment from "moment"
import {
  CLIENT_GAME_ASSIGNMENT_TYPES,
  CLIENT_CUSTOM_INPUT_TYPES
} from "@/config"
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"
import Datepicker from "vuejs-datepicker"
import { MAX_LOGIN_LOGO_WIDTH, CLIENT_IMAGES_MAX_WIDTH } from "@/constants"
import { ExportToCsv } from "export-to-csv"
import { fetchGames } from "@/services/game.service"
import { fetchUsersInPresense } from "@/services/client.service"

export default {
  components: {
    ClientSettingGame,
    ClientSettingMatchEmails,
    VueTimepicker,
    Datepicker,
    TournamentBuilder,
    TournamentControls,
    ClientSettingActionsTabpanel,
    ClientSettingsStylesTabpanel
  },
  props: {
    client: {
      type: Object,
      required: true
    },
    clientID: {
      type: String,
      reuired: true
    },
    copying: {
      type: Boolean,
      required: false
    },
    adding: {
      type: Boolean,
      required: false
    }
  },
  async created() {
    await this.$store.dispatch("fetchHosts")
    this.games = await fetchGames({
      orgID: this.orgID,
      clientID: this.clientID
    })
    // await this.$store.dispatch("pregame/subscribeToClientGames")
    this.object = {
      ...this.client,
      regularLogin: !!this.client.regularLogin
    }
    if (!this.object.numOfPlayers) this.object.numOfPlayers = 100
    if (!this.object.numOfTeams) this.object.numOfTeams = 3
    const snapshot = await firebase
      .database()
      .ref(`auth/client/${this.clientID}/password`)
      .once("value")
    this.password = snapshot.val()
    await this.fetchGames()
    // console.log("CREATED OBJECT", this.object)
    if (this.object.endTimestamp) {
      const date = new Date(this.object.endTimestamp)
      this.endDate = date
      const hours = ("0" + date.getHours()).substr(-2)
      const minutes = ("0" + date.getMinutes()).substr(-2)
      const seconds = ("0" + date.getSeconds()).substr(-2)
      this.endTime = {
        HH: hours,
        mm: minutes,
        ss: seconds
      }
    }
  },
  data() {
    return {
      games: null,
      customInputTypes: CLIENT_CUSTOM_INPUT_TYPES,
      tab: "GAMES",
      newTournamentGameID: null,
      tournyGameID1: null,
      tournyGameID2: null,
      tournyGameID3: null,
      tournyGameID4: null,
      tournyGameID5: null,
      round1Teams: 3,
      round2Teams: 3,
      round3Teams: 3,
      round4Teams: 3,
      round5Teams: 3,
      numOfPlayers: 200,
      attendance: [],
      attendanceNum: 0,
      emails: {},
      statistic: {},
      object: null,
      showPassword: false,
      password: null,
      initializing: false,
      loadingImage: false,
      masterGames: [],
      userGames: [],
      gamesObj: {},
      restrictions: CLIENT_GAME_ASSIGNMENT_TYPES,
      MAX_LOGIN_LOGO_WIDTH,
      CLIENT_IMAGES_MAX_WIDTH,
      rounds: [1, 2, 3, 4, 5, 6],
      numOfTeams: 3,
      playersPerTeam: 5,
      gamesCreated: 0,
      currentRound: -1,
      endDate: null,
      endTime: null,
      gameTime: null,
      gameDate: null,
      startTime: null,
      startDate: null
    }
  },
  watch: {
    tab: {
      async handler(tabName) {
        if (tabName == "ATTENDANCE") {
          const usersInPresense = await fetchUsersInPresense({
            clientID: this.clientID
          })
          const usersTeam = {}
          Object.values(this.tournaments || {}).forEach(t =>
            this.updateUsersTeamFromGames(usersTeam, t.games || [])
          )
          console.log("usersTeam", usersTeam)
          const array = []
          Object.entries(usersInPresense || {}).forEach(([id, user]) => {
            const { games, firstname, lastname, identifier } = user
            const gameIDs = Object.keys(games || {})
            const row = {
              id,
              firstname,
              lastname,
              firstSeen: moment(user.firstSeen).format("MM/DD/YYYY hh:mm"),
              lastSeen: moment(user.lastSeen || user.updated).format(
                "MM/DD/YYYY hh:mm"
              ), // user.updated - for old records
              teamName: null,
              teamID: null,
              teamScore: null,
              gameName: null,
              gameID: null,
              identifier
            }
            let gamePushed = false
            const userTeam = usersTeam[id] || {}
            gameIDs.forEach(gameID => {
              const game = this.games[gameID]
              if (!game || !game.name) return
              const teamData = userTeam[gameID] || {}
              const { teamID, teamName, teamScore } = teamData
              gamePushed = true
              array.push({
                ...row,
                teamID,
                teamName,
                teamScore,
                gameID,
                gameName: game.externalName + " -- " + game.name,
                identifier
              })
            })
            //For cases  if statistic wasn't  loaded
            if (!gamePushed) array.push(row)
          })
          this.attendance = array
          this.attendanceNum = Object.keys(usersInPresense || {}).length
        } else if (tabName === "GAMES") {
          this.games = await fetchGames({
            orgID: this.orgID,
            clientID: this.clientID
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("Games", ["copyGame", "updateGameAny"]),
    onThemeInput({ themeID }) {
      this.$set(this.object, "themeID", themeID)
      this.update()
    },
    async onGameUpdate() {
      this.games = await fetchGames({
        orgID: this.orgID,
        clientID: this.clientID
      })
    },
    async nextRound() {
      if (this.currentRound + 1 <= this.numOfRounds) {
        await this.stage()
        await this.unstage()
        await this.deactivate()
        this.currentRound++
      }
      await this.onGameUpdate()
    },
    async setStartTime() {
      await Promise.all([
        this.filteredGamesArray.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            startTimestamp: this.startTimestamp
          })
        })
      ])
      await this.onGameUpdate()
    },
    async setEndTime() {
      console.log("Set End Time", this.endTimestamp)
      var obj = {}
      obj.endTimestamp = this.endTimestamp
      obj.id = this.clientID
      // console.log("CLIENT", obj)
      const updatedClient = await this.$services
        .getService("client")
        .then(service => service.updateClient(obj))

      if (updatedClient) {
        this.$emit("input", updatedClient)
      }
    },
    async changeMinutes(minutes) {
      await Promise.all([
        this.filteredGamesArray.map(game => {
          const startTimestamp = game.startTimestamp + minutes * 60 * 1000
          return this.updateGameAny({
            theKey: game.theKey,
            startTimestamp
          })
        })
      ])
      await this.onGameUpdate()
    },
    async startNow() {
      await Promise.all([
        this.filteredGamesArray.map(game => {
          const startTimestamp = Date.now() + 1000
          return this.updateGameAny({
            theKey: game.theKey,
            startTimestamp
          })
        })
      ])
      await this.onGameUpdate()
    },
    async stage() {
      await Promise.all([
        this.filteredGamesArray.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            ondeck: true
          })
        })
      ])
      await this.onGameUpdate()
    },
    async unstage() {
      await Promise.all([
        this.filteredGamesArray.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            ondeck: false
          })
        })
      ])
      await this.onGameUpdate()
    },
    async activate() {
      await Promise.all([
        this.filteredGamesArray.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            deactivate: false
          })
        })
      ])
      await this.onGameUpdate()
    },
    async deactivate() {
      await Promise.all([
        this.filteredGamesArray.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            deactivate: true
          })
        })
      ])
      await this.onGameUpdate()
    },
    async addGame() {
      if (!this.newTournamentGameID) throw new Error("No game ID is given")
      if (!this.clientID) throw new Error("No client is given")

      const games = this.gamesObj
      const game = { ...games[this.newTournamentGameID] }

      if (!game) throw new Error("Game data is undefined")

      game.clientID = this.clientID
      game.theKey = this.newTournamentGameID
      game.sameClientID = true
      game.ondeck = true
      game.deactivate = true
      game.defaultKeynote = false
      game.runStatus = "Tournament"
      console.log("Game to copy", game)
      try {
        const startIn = Date.now() + 1000 * 60 * 30
        const newGameID = await this.copyGame({ game, startIn })
        console.log("New game created", newGameID)
      } catch (e) {
        console.error("Error in Games/copyGame")
        console.error(e)
      }
      await this.onGameUpdate()
    },
    async createTournament() {
      console.log("CREATE TOURNAMENT", this.tournyGameID1)
      console.log("THE OBJ", this.object)
      this.object.theKey = this.clientID
      this.object.id = this.clientID
      const updatedClient = await this.$services
        .getService("client")
        .then(service => service.updateClient(this.object))
      if (updatedClient) {
        this.$emit("input", updatedClient)
      }
      if (this.tournyGameID1) {
        console.log("COPYING ROUND 1")
        for (let i = 0; i < this.round1Games; i++) {
          await this.addTournyGame(
            this.tournyGameID1,
            1,
            this.object.round1Teams
          )
          this.gamesCreated++
        }
      }
      if (this.tournyGameID2) {
        console.log("COPYING ROUND 2")
        for (let i = 0; i < this.round2Games; i++) {
          await this.addTournyGame(
            this.tournyGameID2,
            2,
            this.object.round2Teams
          )
          this.gamesCreated++
        }
      }
      if (this.tournyGameID3) {
        console.log("COPYING ROUND 3")
        for (let i = 0; i < this.round3Games; i++) {
          await this.addTournyGame(
            this.tournyGameID3,
            3,
            this.object.round3Teams
          )
          this.gamesCreated++
        }
      }
      if (this.tournyGameID4) {
        console.log("COPYING ROUND 4")
        for (let i = 0; i < this.round4Games; i++) {
          await this.addTournyGame(
            this.tournyGameID4,
            4,
            this.object.round4Teams
          )
          this.gamesCreated++
        }
      }
      if (this.tournyGameID5) {
        console.log("COPYING ROUND 5")
        for (let i = 0; i < this.round5Games; i++) {
          await this.addTournyGame(
            this.tournyGameID5,
            5,
            this.object.round5Teams
          )
          this.gamesCreated++
        }
      }
      this.gamesCreated = 0
      await this.onGameUpdate()
    },
    async addTournyGame(gameID, round, teams) {
      const games = this.gamesObj
      const game = { ...games[gameID] }
      if (!game) throw new Error("Game data is undefined")
      game.clientID = this.clientID
      game.theKey = gameID
      game.sameClientID = true
      game.ondeck = true
      game.deactivate = false
      game.defaultKeynote = false
      game.runStatus = "Tournament"
      game.round = round
      game.numOfTeams = teams
      console.log("Game to copy", game)
      try {
        const startIn = Date.now() + 1000 * 60 * 30
        const newGameID = await this.copyGame({ game, startIn })
        console.log("New game created", newGameID)
      } catch (e) {
        console.error("Error in Games/copyGame")
        console.error(e)
      }
      await this.onGameUpdate()
    },
    async fetchGames() {
      // console.log("FETCHING GAMES")
      const snapshot = await firebase
        .database()
        .ref("org/" + this.$store.getters.orgID + "/games")
        .orderByChild("runStatus")
        .equalTo("Masters")
        .once("value")

      const gamesObj = {}
      snapshot.forEach(item => {
        gamesObj[item.key] = item.val()
      })
      // console.log("GAMES OBJ", gamesObj)
      var arr = Object.entries(gamesObj)
      // console.log("ARR", arr)
      arr.reverse()
      var newArr = []
      for (let i in arr) {
        const obj = {}
        obj.id = arr[i][0]
        obj.name = arr[i][1].name + " - " + arr[i][1].internalName
        newArr.push(obj)
      }
      this.gamesObj = gamesObj
      // console.log("MASTER GAMES", obj)
      this.masterGames = newArr
      const snapshot2 = await firebase
        .database()
        .ref("org/" + this.$store.getters.orgID + "/games")
        .orderByChild("runStatus")
        .equalTo("User Templates")
        .once("value")

      const gamesObj2 = {}
      snapshot2.forEach(item => {
        gamesObj2[item.key] = item.val()
      })
      // console.log("GAMES OBJ", gamesObj)
      arr = Object.entries(gamesObj2)
      // console.log("ARR", arr)
      arr.reverse()
      newArr = []
      for (let i in arr) {
        const obj = {}
        obj.id = arr[i][0]
        obj.name = arr[i][1].name + " - " + arr[i][1].internalName
        newArr.push(obj)
      }
      // console.log("MASTER GAMES", obj)
      this.userGames = newArr
    },
    async fetchEmail({ userID }) {
      const snapshot = await firebase
        .database()
        .ref(`users/private/${userID}/email`)
        .once("value")
      const value = snapshot.val() || "none"
      const emails = { ...this.emails }
      emails[userID] = value
      this.emails = emails
    },
    async fetchAllEmails() {
      const promises = {}
      this.attendance.forEach(item => {
        if (promises[item.id]) return
        promises[item.id] = this.fetchEmail({ userID: item.id })
      })
      return await Promise.all(Object.values(promises))
    },

    async onGameSelect({ gameID, clientID }) {
      if (!gameID) throw new Error("No game ID")
      if (!clientID) throw new Error("No client ID")
      this.initializing = true
      try {
        await this.$store.dispatch("auth/initializeToGame", {
          gameID,
          clientID
        })
        const hasPreGame = this.$store.state.auth.hasPreGame
        const game = this.$store.state.game
        const room = game.started ? "game" : "lobby"
        if (hasPreGame) {
          await this.$router.push(`/${room}/${clientID}${gameID}`)
        } else {
          await this.$router.push(`/${room}/${clientID}`)
        }
      } catch (e) {
        console.error(e)
      }
      this.initializing = false
    },
    async changeDefaultKeynote({ theKey }) {
      await Promise.all(
        this.gamesArray
          .filter(game => game.defaultKeynote && game.theKey !== theKey)
          .map(({ theKey }) =>
            this.updateGameAny({ theKey, defaultKeynote: false })
          )
      )
      await this.onGameUpdate()
    },
    async purge() {
      await Promise.all([
        firebase
          .database()
          .ref(`client/${this.clientID}/gamesStatusUpdateQueue`)
          .set(null),
        firebase
          .database()
          .ref(`client/${this.clientID}/usersPlayingGamesQueue`)
          .set(null),
        firebase
          .database()
          .ref(`client/${this.clientID}/usersSpreadQueue`)
          .set(null),
        firebase
          .database()
          .ref(`client/${this.clientID}/usersPlayedGames`)
          .set(null)
      ])
    },
    async update() {
      try {
        if (!this.object.imageUrl) delete this.object.imageUrl

        if (this.object.regularLogin) this.password = null

        if (!this.password) {
          await firebase
            .database()
            .ref(`auth/client/${this.clientID}`)
            .update({ auth: null, password: null, user: null })
        } else {
          await firebase
            .database()
            .ref(`auth/client/${this.clientID}`)
            .update({ auth: true, password: this.password })
        }

        this.object.id = this.clientID
        const updatedClient = await this.$services
          .getService("client")
          .then(service => service.updateClient(this.object))

        if (updatedClient) {
          this.$emit("input", updatedClient)
        }
      } finally {
        this.$emit("closeClientSettings")
      }
    },
    cancel() {
      this.loadingImage = false
      this.$emit("closeClientSettings")
      console.log("CANCEL")
    },

    async setImage(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.object.name}.png`
        this.object.logoImage = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("which type = " + this.object.logoImage)
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async setCustomImage(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.object.name}.png`
        this.object.customLogo = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("which type = " + this.object.customLogo)
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async setLoginLogo(e) {
      this.loadingImage = true
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        const fileName = `gamephotos/${uniqid()}-${this.object.name}.png`
        this.object.loginLogo = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingImage = false
      }
    },
    deleteLoginLogo() {
      this.object.loginLogo = ""
    },
    /**
     * @param
     * @param {number} index
     */
    async updateImages(payload, index) {
      this.loadingImage = true
      try {
        const { object } = this
        const array = object.images || new Array(6).fill("")
        const blob = await getBlobFromDataURL(payload.dataUrl)
        const fileName = `gamephotos/${uniqid()}-${object.name}.png`
        const url = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        array[index] = url
        // There might not be 'images' property during Vue.js get/set part
        this.$set(this.object, "images", array)
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingImage = false
      }
    },
    imagesDeleteHandler(index) {
      this.$set(this.object.images, index, "")
    },
    async exportCSV() {
      const fieldsMap = {
        id: "User ID",
        firstname: "First Name",
        lastname: "Last Name",
        firstSeen: "First Session",
        lastSeen: "Last Session",
        gameName: "Game Name",
        gameID: "Game ID",
        teamID: "Team ID",
        teamName: "Team Name",
        teamScore: "Team Score"
      }
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        // title: "Attendences",
        filename: "Attendence",
        useTextFile: false,
        useBom: true,
        headers: Object.values(fieldsMap)
      }
      const csvExporter = new ExportToCsv(options)
      await this.fetchAllEmails()
      const keys = Object.keys(fieldsMap)
      const data = this.attendenceWithEmail.map(item => {
        const row = {}
        // Fields mapping and replacing of "null" values by empty
        keys.forEach(key => (row[key] = item[key] ? item[key] : ""))
        return row
      })

      csvExporter.generateCsv(data)
    },
    /**
     * Clear email
     * If client.matchEmails is not empty we re using one-time users like `${clientID}.${email}`
     *
     **/
    clearEmail(email) {
      email = email || ""
      return email.replace(`${this.clientID}.`, "")
    },
    /**
     * @param usersTeam - mutable object
     * @param games - games from  tournament obj (used for team statistic)
     **/
    updateUsersTeamFromGames(usersTeam, games) {
      Object.entries(games).forEach(([originalGameID, game]) => {
        const { teams } = game
        if (!teams) return
        Object.entries(teams).forEach(([teamID, team]) => {
          const { score: teamScore, users, name: teamName, gameID } = team
          if (!users) return
          Object.keys(users).forEach(userID => {
            if (!usersTeam[userID]) usersTeam[userID] = {}
            usersTeam[userID][gameID] = {
              teamID,
              teamName,
              teamScore,
              originalGameID
            }
          })
        })
      })
      return usersTeam
    }
  },
  computed: {
    ...mapGetters("auth", ["token"]),
    ...mapGetters("pregame", ["tournaments"]),
    ...mapGetters(["orgID"]),
    gamesArray() {
      const array = []
      const games = this.games || {}
      for (let key in games) {
        const game = this.games[key]
        game.id = key
        array.push(game)
      }
      return array
    },
    tournament() {
      const [key] = Object.keys(this.tournaments || {}).filter(
        key => parseInt(key) !== 0
      )
      return key && this.tournaments ? this.tournaments[key] : null
    },
    tournamentGames() {
      return this.tournament
        ? Object.entries(this.tournament.games || {})
            .map(([id, game]) => ({ ...game, id }))
            .sort((a, b) => a.timestamp - b.timestamp)
        : []
    },
    gamesGroupedByRound() {
      return this.gamesArray
        .filter(item => !item.deletedTimestamp)
        .reduce((acc, val) => {
          if (val.round && acc[val.round]) {
            acc[val.round].push(val)
          } else if (val.round) {
            acc[val.round] = [val]
          }
          return acc
        }, {})
    },
    roundGamesCustom() {
      return Object.keys(this.gamesGroupedByRound)
        .map(key => parseInt(key))
        .sort()
    },
    numOfCustomRounds() {
      return this.roundGamesCustom[this.roundGamesCustom.length - 1] || 0
    },
    numOfRounds() {
      return Math.max(this.tournamentGames.length, this.numOfCustomRounds)
    },
    hasNextRound() {
      return this.currentRound - 1 < this.numOfRounds && this.numOfRounds
    },
    endTimestamp() {
      if (!this.endDate) return
      const date = new Date(this.endDate)
      const { HH, mm, ss } = this.endTime
      const hours = parseInt(HH)
      const minutes = parseInt(mm)
      const seconds = parseInt(ss)

      date.setHours(hours)
      date.setMinutes(minutes)
      date.setSeconds(seconds)

      return date.getTime()
    },
    startTimestamp() {
      if (!this.startDate) return
      const date = new Date(this.startDate)
      const { HH, mm, ss } = this.startTime
      const hours = parseInt(HH)
      const minutes = parseInt(mm)
      const seconds = parseInt(ss)

      date.setHours(hours)
      date.setMinutes(minutes)
      date.setSeconds(seconds)

      return date.getTime()
    },
    tournyPlayers() {
      var arr = []
      for (var i = 0; i <= 1500; i = i + 25) {
        arr.push(i)
      }
      return arr
    },
    round1Games() {
      return Math.ceil(
        this.object.numOfPlayers /
          (this.object.round1Teams * this.object.playersPerTeam)
      )
    },
    round2Games() {
      return Math.ceil(this.round1Games / this.object.round2Teams)
    },
    round3Games() {
      return Math.ceil(this.round2Games / this.object.round3Teams)
    },
    round4Games() {
      return Math.ceil(this.round3Games / this.object.round4Teams)
    },
    round5Games() {
      return Math.ceil(this.round4Games / this.object.round5Teams)
    },
    attendenceWithEmail() {
      console.log(this.attendance)

      return this.attendance.map(user => ({
        ...user,
        email: this.clearEmail(this.emails[user.id])
      }))
    },
    regularLogin: {
      get() {
        return !!this.object.regularLogin
      },
      set(value) {
        this.object.regularLogin = value
      }
    },
    isTournament() {
      return this.client.tournamentMode
    },
    filteredGamesArray() {
      const index = this.currentRound - 1
      if (index > -1) {
        return this.gamesArray.filter(item => {
          if (!item) return false
          if (item.deletedTimestamp) return false
          if (item.tournamentID) {
            return (
              this.tournamentGames[index] &&
              item.originalGameID === this.tournamentGames[index].id
            )
          } else {
            return parseInt(item.round) === this.currentRound
          }
        })
      } else {
        return this.gamesArray.filter(item => !item.deletedTimestamp)
      }
    },
    hosts() {
      var arr = Object.values(this.$store.getters.hosts)
      arr = arr.filter(item => item.orgID == this.orgID && !item.deleted)
      arr = arr.sort((a, b) => {
        var textA = a.firstname.toUpperCase()
        var textB = b.firstname.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      return arr
    },
    images() {
      return this.object.images || new Array(6).fill("")
    }
  }
}
</script>
<style lang="scss">
.rtb-max-w-full {
  width: 200px;
}
.client-setting-rr {
  background-color: #fff;
  .email-link {
    cursor: pointer;
    color: $primary_accent_color;
    text-decoration: underline;
  }
  .user-email {
    overflow-x: auto;
    white-space: nowrap;
  }
  .rr-client-settings-tab {
    cursor: pointer;
    text-decoration: underline;

    &.selected {
      background-color: $primary_accent_color;
      color: #fff;
    }
  }
  .scrollable {
    overflow-y: auto;
  }
  .table-header--header {
    text-align: left;
    text-transform: uppercase;
  }
  .table-header--buttons {
    text-align: right;
  }
}
</style>
