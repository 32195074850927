<template>
  <v-dialog v-model="visible" scrollable>
    <ThemeCustomizer
      v-bind="$attrs"
      v-on="$listeners"
      @save="onSave"
      @delete="onDelete"
    />
  </v-dialog>
</template>

<script>
import Vue from "vue"
import mixins from "vue-typed-mixins"

import { ModalMixin } from "@/mixins/Modal"

import ThemeCustomizer from "./ThemeCustomizer.vue"

export default mixins(Vue.extend(ModalMixin)).extend({
  name: "ThemeCustomizerModal",
  inheritAttrs: false,
  components: {
    ThemeCustomizer
  },
  methods: {
    onSave() {
      this.closeModal()
    },
    onDelete() {
      this.closeModal()
    }
  }
})
</script>
