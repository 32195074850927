var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "client-setting-rr", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { attrs: { shrink: "", "pa-4": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "GAMES" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "GAMES"
                        }
                      }
                    },
                    [_vm._v(" GAMES ")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "TOURNAMENT" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "TOURNAMENT"
                        }
                      }
                    },
                    [_vm._v(" TOURNAMENT ")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "EDIT" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "EDIT"
                        }
                      }
                    },
                    [_vm._v(" EDIT ")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "ATTENDANCE" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "ATTENDANCE"
                        }
                      }
                    },
                    [_vm._v(" ATTENDANCE ")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "STYLE" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "STYLE"
                        }
                      }
                    },
                    [_vm._v("STYLE")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "IMAGES" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "IMAGES"
                        }
                      }
                    },
                    [_vm._v("IMAGES ")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "MATCH_EMAILS" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "MATCH_EMAILS"
                        }
                      }
                    },
                    [_vm._v(" MATCH EMAILS ")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "rr-client-settings-tab",
                      class: _vm.tab == "ACTIONS" ? "selected" : null,
                      attrs: { shrink: "", "pl-2": "", "pr-2": "" },
                      on: {
                        click: function($event) {
                          _vm.tab = "ACTIONS"
                        }
                      }
                    },
                    [_vm._v(" ACTIONS ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.tab == "GAMES"
            ? _c(
                "v-flex",
                {
                  staticClass: "scrollable",
                  attrs: { "pa-4": "", "d-flex": "" }
                },
                [
                  !!_vm.object
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticStyle: { "margin-top": "-20px" },
                              attrs: { column: "" }
                            },
                            [
                              _c(
                                "v-tabs",
                                {
                                  model: {
                                    value: _vm.currentRound,
                                    callback: function($$v) {
                                      _vm.currentRound = $$v
                                    },
                                    expression: "currentRound"
                                  }
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.currentRound = -1
                                        }
                                      }
                                    },
                                    [_vm._v(" All")]
                                  ),
                                  _vm._l(_vm.numOfRounds, function(i) {
                                    return _c("v-tab", { key: i }, [
                                      _vm._v(" Round " + _vm._s(i) + " ")
                                    ])
                                  })
                                ],
                                2
                              ),
                              _c("br"),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.stage()
                                        }
                                      }
                                    },
                                    [_vm._v(" Stage ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.unstage()
                                        }
                                      }
                                    },
                                    [_vm._v(" Un Stage ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.activate()
                                        }
                                      }
                                    },
                                    [_vm._v(" On ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deactivate()
                                        }
                                      }
                                    },
                                    [_vm._v(" Off ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeMinutes(-5)
                                        }
                                      }
                                    },
                                    [_vm._v(" - 5 ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeMinutes(-1)
                                        }
                                      }
                                    },
                                    [_vm._v(" - 1 ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.startNow()
                                        }
                                      }
                                    },
                                    [_vm._v(" Start Now ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeMinutes(1)
                                        }
                                      }
                                    },
                                    [_vm._v(" + 1 ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeMinutes(5)
                                        }
                                      }
                                    },
                                    [_vm._v(" + 5 ")]
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm1: "", "mx-2": "" } },
                                    [
                                      _c("datepicker", {
                                        staticStyle: {
                                          "margin-top": "5px",
                                          width: "100px"
                                        },
                                        attrs: { placeholder: "Game Date" },
                                        model: {
                                          value: _vm.startDate,
                                          callback: function($$v) {
                                            _vm.startDate = $$v
                                          },
                                          expression: "startDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm1: "", "mx-2": "" } },
                                    [
                                      _c("vue-timepicker", {
                                        attrs: { format: "HH:mm:ss" },
                                        model: {
                                          value: _vm.startTime,
                                          callback: function($$v) {
                                            _vm.startTime = $$v
                                          },
                                          expression: "startTime"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setStartTime()
                                        }
                                      }
                                    },
                                    [_vm._v(" Set Start ")]
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm1: "", "mx-2": "" } },
                                    [
                                      _c("datepicker", {
                                        staticStyle: {
                                          "margin-top": "5px",
                                          width: "100px"
                                        },
                                        attrs: { placeholder: "End Game Date" },
                                        model: {
                                          value: _vm.endDate,
                                          callback: function($$v) {
                                            _vm.endDate = $$v
                                          },
                                          expression: "endDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm1: "", "mx-2": "" } },
                                    [
                                      _c("vue-timepicker", {
                                        attrs: { format: "HH:mm:ss" },
                                        model: {
                                          value: _vm.endTime,
                                          callback: function($$v) {
                                            _vm.endTime = $$v
                                          },
                                          expression: "endTime"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setEndTime()
                                        }
                                      }
                                    },
                                    [_vm._v(" Set Master End ")]
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _c("br")
                                ],
                                1
                              ),
                              _vm._l(_vm.filteredGamesArray, function(game, i) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: "client-game-" + game.theKey,
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticStyle: {
                                              "margin-top": "-15px"
                                            },
                                            attrs: { "d-flex": "" }
                                          },
                                          [
                                            _c("ClientSettingGame", {
                                              attrs: {
                                                position: i + 1,
                                                game: game,
                                                users: _vm.hosts,
                                                initializing: _vm.initializing
                                              },
                                              on: {
                                                update: _vm.onGameUpdate,
                                                changeDefaultKeynote:
                                                  _vm.changeDefaultKeynote,
                                                onSelect: function($event) {
                                                  return _vm.onGameSelect({
                                                    gameID: game.theKey,
                                                    clientID: game.clientID
                                                  })
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticStyle: {
                                              "margin-top": "-18px"
                                            },
                                            attrs: { shrink: "", "ml-3": "" }
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { column: "" } },
                                              [
                                                _c("v-flex", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/gamelogin/" +
                                                          game.clientID +
                                                          game.theKey +
                                                          "?auth=0",
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [_vm._v(" Login ")]
                                                  )
                                                ]),
                                                _c("v-flex", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/gamelogin/" +
                                                          game.clientID +
                                                          game.theKey +
                                                          "?audit=1&auth=0",
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [_vm._v(" Audit ")]
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.masterGames,
                                      label: "Copy Master Game To Event",
                                      "item-text": "name",
                                      "item-value": "id"
                                    },
                                    model: {
                                      value: _vm.newTournamentGameID,
                                      callback: function($$v) {
                                        _vm.newTournamentGameID = $$v
                                      },
                                      expression: "newTournamentGameID"
                                    }
                                  })
                                ],
                                1
                              ),
                              !!_vm.newTournamentGameID
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs1: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            "mt-3": "",
                                            icon: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addGame()
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("add")])],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.userGames,
                                      label: "Copy User Game To Event",
                                      "item-text": "name",
                                      "item-value": "id"
                                    },
                                    model: {
                                      value: _vm.newTournamentGameID,
                                      callback: function($$v) {
                                        _vm.newTournamentGameID = $$v
                                      },
                                      expression: "newTournamentGameID"
                                    }
                                  })
                                ],
                                1
                              ),
                              !!_vm.newTournamentGameID
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs1: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            "mt-3": "",
                                            icon: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addGame()
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("add")])],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-center": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "secondary",
                                          attrs: { flat: "" },
                                          on: { click: _vm.cancel }
                                        },
                                        [_vm._v("Back To Game")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.tab == "TOURNAMENT"
            ? _c(
                "v-flex",
                { staticClass: "scrollable", attrs: { "pa-4": "" } },
                [
                  _c("TournamentBuilder", {
                    attrs: { clientID: _vm.clientID, orgID: _vm.orgID }
                  }),
                  _c("TournamentControls", { staticClass: "mt-3" })
                ],
                1
              )
            : _vm._e(),
          _vm.tab == "EDIT"
            ? _c(
                "v-flex",
                {
                  staticClass: "scrollable",
                  attrs: { "pa-4": "", "d-flex": "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticStyle: {
                                    "margin-top": "-20px",
                                    "margin-bottom": "20px"
                                  },
                                  attrs: { xs12: "", "mx-2": "" }
                                },
                                [
                                  _c("b", [
                                    _vm._v("Game Link: "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "https://remoteteambuilding.com/login/" +
                                            _vm.clientID +
                                            "?auth=0"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "https://remoteteambuilding.com/login/" +
                                            _vm._s(_vm.clientID) +
                                            "?auth=0"
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Name", required: "" },
                                    model: {
                                      value: _vm.object.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.object, "name", $$v)
                                      },
                                      expression: "object.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.restrictions,
                                      label: "Game Assignment Type",
                                      "item-text": "text",
                                      "item-value": "code"
                                    },
                                    model: {
                                      value: _vm.object.restriction,
                                      callback: function($$v) {
                                        _vm.$set(_vm.object, "restriction", $$v)
                                      },
                                      expression: "object.restriction"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs12: "",
                                            "pr-4": ""
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "NOTES",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.object.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "object.description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs2: ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label:
                                                "Locked (WILL REMOVE EVERYONE)"
                                            },
                                            model: {
                                              value: _vm.object.locked,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "locked",
                                                  $$v
                                                )
                                              },
                                              expression: "object.locked"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs2: ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "DEBUG" },
                                            model: {
                                              value: _vm.object.debug,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "debug",
                                                  $$v
                                                )
                                              },
                                              expression: "object.debug"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs2: ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "Use Avatar" },
                                            model: {
                                              value: _vm.object.noUserPhoto,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "noUserPhoto",
                                                  $$v
                                                )
                                              },
                                              expression: "object.noUserPhoto"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs2: ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "Onboard Chat" },
                                            model: {
                                              value: _vm.client.onboardChat,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.client,
                                                  "onboardChat",
                                                  $$v
                                                )
                                              },
                                              expression: "client.onboardChat"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs2: ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "Hide Hosts" },
                                            model: {
                                              value: _vm.object.anonymousUsers,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "anonymousUsers",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "object.anonymousUsers"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticStyle: {
                                            "margin-top": "-15px"
                                          },
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs2: ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "No Expo Tour" },
                                            model: {
                                              value: _vm.object.noExpoTour,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "noExpoTour",
                                                  $$v
                                                )
                                              },
                                              expression: "object.noExpoTour"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            xs2: ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "Email Login" },
                                            model: {
                                              value: _vm.object.regularLogin,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "regularLogin",
                                                  $$v
                                                )
                                              },
                                              expression: "object.regularLogin"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              !_vm.object.regularLogin
                                ? _c("v-flex", { attrs: { xs6: "" } }, [
                                    _c(
                                      "form",
                                      {
                                        attrs: { autocomplete: "off" },
                                        on: {
                                          submit: function($event) {
                                            $event.preventDefault()
                                            return (function() {})($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            autocomplete: "new-password",
                                            placeholder: " ",
                                            "append-icon": _vm.showPassword
                                              ? "visibility"
                                              : "visibility_off",
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password",
                                            label: "Optional Password"
                                          },
                                          on: {
                                            "click:append": function($event) {
                                              _vm.showPassword = !_vm.showPassword
                                            }
                                          },
                                          model: {
                                            value: _vm.password,
                                            callback: function($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { sm7: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Annoucement",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.object.announcement,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "announcement",
                                          $$v
                                        )
                                      },
                                      expression: "object.announcement"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { sm3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Return Url",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.object.returnUrl,
                                      callback: function($$v) {
                                        _vm.$set(_vm.object, "returnUrl", $$v)
                                      },
                                      expression: "object.returnUrl"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs1: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Max players capacity" },
                                    model: {
                                      value: _vm.object.maxCapacity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "maxCapacity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "object.maxCapacity"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" Front login page"),
                              _c("br"),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    xs2: ""
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Hide GoGame" },
                                    model: {
                                      value: _vm.object.hideGoGame,
                                      callback: function($$v) {
                                        _vm.$set(_vm.object, "hideGoGame", $$v)
                                      },
                                      expression: "object.hideGoGame"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tagline" },
                                    model: {
                                      value: _vm.object.tagline,
                                      callback: function($$v) {
                                        _vm.$set(_vm.object, "tagline", $$v)
                                      },
                                      expression: "object.tagline"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tagline Hex Color" },
                                    model: {
                                      value: _vm.object.taglineColor,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "taglineColor",
                                          $$v
                                        )
                                      },
                                      expression: "object.taglineColor"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Later Comer You Tube Code "
                                    },
                                    model: {
                                      value: _vm.object.latecomerVideoCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "latecomerVideoCode",
                                          $$v
                                        )
                                      },
                                      expression: "object.latecomerVideoCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { sm3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Redirect URL",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.object.redirectURL,
                                      callback: function($$v) {
                                        _vm.$set(_vm.object, "redirectURL", $$v)
                                      },
                                      expression: "object.redirectURL"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { "d-flex": "", xs12: "", "mx-2": "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.customInputTypes,
                                              label: "CUSTOM INPUT",
                                              "item-text": "label",
                                              "item-value": "value"
                                            },
                                            model: {
                                              value: _vm.object.customInputType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.object,
                                                  "customInputType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "object.customInputType"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      !!_vm.object.customInputType
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Custom Input Label"
                                                },
                                                model: {
                                                  value:
                                                    _vm.object.customInputLabel,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.object,
                                                      "customInputLabel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "object.customInputLabel"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.object.customInputType === "select"
                                        ? _c(
                                            "v-flex",
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  label:
                                                    "Custom Input Options (comma separated)"
                                                },
                                                model: {
                                                  value:
                                                    _vm.object
                                                      .customInputOptions,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.object,
                                                      "customInputOptions",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "object.customInputOptions"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-center": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    loading: _vm.loadingImage,
                                    flat: ""
                                  },
                                  on: { click: _vm.update }
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secondary",
                                  attrs: { flat: "" },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("Back")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secondary",
                                  attrs: { flat: "" },
                                  on: { click: _vm.purge }
                                },
                                [_vm._v("Purge")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.tab == "ATTENDANCE"
            ? _c(
                "v-flex",
                {
                  staticClass: "scrollable",
                  attrs: { "pa-4": "", "d-flex": "" }
                },
                [
                  _c("v-layout", { attrs: { column: "" } }, [
                    _c(
                      "table",
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "table-header--header",
                                attrs: { colspan: "5" }
                              },
                              [
                                _c("b", [_vm._v("Total attendance:")]),
                                _vm._v(" " + _vm._s(_vm.attendanceNum) + " ")
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "table-header--buttons",
                                attrs: { colspan: "4" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary",
                                    attrs: { flat: "", small: "" },
                                    on: { click: _vm.exportCSV }
                                  },
                                  [_vm._v("Export to CSV")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "secondary",
                                    attrs: { flat: "", small: "" },
                                    on: { click: _vm.fetchAllEmails }
                                  },
                                  [_vm._v("Show All Emails")]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("FIRSTNAME")]),
                          _c("td", [_vm._v("LASTNAME")]),
                          _c("td", [_vm._v("IDENTIFIER")]),
                          _c("td", [_vm._v("GAME")]),
                          _c("td", [_vm._v("TEAM")]),
                          _c("td", [_vm._v("SCORE")]),
                          _c("td", [_vm._v("FIRST SEEN")]),
                          _c("td", [_vm._v("LAST SEEN")]),
                          _c("td", [_vm._v("EMAIL")])
                        ]),
                        _c("tr"),
                        _vm._l(_vm.attendenceWithEmail, function(user, key) {
                          return _c(
                            "tr",
                            { key: "attendance-" + user.id + "-" + key },
                            [
                              _c("td", [_vm._v(_vm._s(user.firstname))]),
                              _c("td", [_vm._v(_vm._s(user.lastname))]),
                              _c("td", [_vm._v(_vm._s(user.identifier))]),
                              _c("td", [_vm._v(_vm._s(user.gameName))]),
                              _c("td", [_vm._v(_vm._s(user.teamName))]),
                              _c("td", [_vm._v(_vm._s(user.teamScore))]),
                              _c("td", [_vm._v(_vm._s(user.firstSeen))]),
                              _c("td", [_vm._v(_vm._s(user.lastSeen))]),
                              !!user.email && user.email !== "none"
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "user-email",
                                      attrs: { xs3: "" }
                                    },
                                    [_vm._v(" " + _vm._s(user.email) + " ")]
                                  )
                                : user.email !== "none"
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "email-link",
                                      attrs: { xs3: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.fetchEmail({
                                            userID: user.id
                                          })
                                        }
                                      }
                                    },
                                    [_vm._v(" SHOW ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm.tab == "STYLE"
            ? _c(
                "v-flex",
                {
                  staticClass: "scrollable",
                  attrs: { "pa-4": "", "d-flex": "" }
                },
                [
                  _c("ClientSettingsStylesTabpanel", {
                    attrs: { client: _vm.object },
                    on: { "input:theme": _vm.onThemeInput, save: _vm.update }
                  })
                ],
                1
              )
            : _vm.tab == "IMAGES"
            ? _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    { staticClass: "pa-2", attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs5: "", "mx-2": "" } },
                        [
                          _vm._v(" Top Left Image "),
                          _c("img", {
                            attrs: { width: "150", src: _vm.object.logoImage }
                          }),
                          _c("image-uploader", {
                            attrs: {
                              debug: 1,
                              "max-width": 1200,
                              quality: 1,
                              "auto-rotate": true,
                              preview: false,
                              "class-name": [
                                "fileinput",
                                { "fileinput--loaded": _vm.object.logoImage }
                              ],
                              "output-format": "verbose",
                              capture: "environment",
                              accept: ".jpg,.png,.jpeg"
                            },
                            on: {
                              input: function($event) {
                                return _vm.setImage($event, _vm.client)
                              }
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Top Left Photo" },
                            model: {
                              value: _vm.object.logoImage,
                              callback: function($$v) {
                                _vm.$set(_vm.object, "logoImage", $$v)
                              },
                              expression: "object.logoImage"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs5: "", "mx-2": "" } },
                        [
                          _c("v-switch", {
                            attrs: { label: "Hide Expo Text" },
                            model: {
                              value: _vm.object.hideExpoText,
                              callback: function($$v) {
                                _vm.$set(_vm.object, "hideExpoText", $$v)
                              },
                              expression: "object.hideExpoText"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs5: "", "mx-2": "" } },
                        [
                          _vm._v(" Expo Custom Logo "),
                          _c("img", {
                            attrs: { width: "150", src: _vm.object.customLogo }
                          }),
                          _c("image-uploader", {
                            attrs: {
                              debug: 1,
                              "max-width": 1200,
                              quality: 1,
                              "auto-rotate": true,
                              preview: false,
                              "class-name": [
                                "fileinput",
                                { "fileinput--loaded": _vm.object.customLogo }
                              ],
                              "output-format": "verbose",
                              capture: "environment",
                              accept: ".jpg,.png,.jpeg"
                            },
                            on: {
                              input: function($event) {
                                return _vm.setCustomImage($event, _vm.client)
                              }
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Expo Custom Logo" },
                            model: {
                              value: _vm.object.customLogo,
                              callback: function($$v) {
                                _vm.$set(_vm.object, "customLogo", $$v)
                              },
                              expression: "object.customLogo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "", "mx-2": "" } },
                        [
                          _vm._v(" Expo Custom Logo Style "),
                          _c("v-text-field", {
                            attrs: { label: "Expo Custom Logo Style" },
                            model: {
                              value: _vm.object.customLogoStyle,
                              callback: function($$v) {
                                _vm.$set(_vm.object, "customLogoStyle", $$v)
                              },
                              expression: "object.customLogoStyle"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs5: "" } },
                        [
                          _c("span", { staticClass: "caption" }, [
                            _vm._v("Login logo")
                          ]),
                          !!_vm.object.loginLogo
                            ? _c("img", {
                                staticClass: "rtb-max-w-full",
                                attrs: {
                                  src: _vm.object.loginLogo,
                                  alt: "Login logo"
                                }
                              })
                            : _vm._e(),
                          _c("image-uploader", {
                            attrs: {
                              debug: 1,
                              "max-width": _vm.MAX_LOGIN_LOGO_WIDTH,
                              "auto-rotate": "",
                              preview: false,
                              "class-name": [
                                "fileinput",
                                { "fileinput--loaded": _vm.object.loginLogo }
                              ],
                              "output-format": "verbose",
                              capture: "environment",
                              accept: ".jpg, .png, .jpeg"
                            },
                            on: {
                              input: function($event) {
                                return _vm.setLoginLogo($event, _vm.client)
                              }
                            }
                          }),
                          !!_vm.object.loginLogo
                            ? _c("v-text-field", {
                                staticClass: "mt-4",
                                attrs: {
                                  disabled: "",
                                  label: "Login logo URL",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.object.loginLogo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.object, "loginLogo", $$v)
                                  },
                                  expression: "object.loginLogo"
                                }
                              })
                            : _vm._e(),
                          !!_vm.object.loginLogo
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-0",
                                  attrs: {
                                    color: "error",
                                    depressed: "",
                                    small: ""
                                  },
                                  on: { click: _vm.deleteLoginLogo }
                                },
                                [_vm._v("Delete")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._l(_vm.images, function(image, index) {
                        return _c(
                          "v-flex",
                          { key: index, attrs: { xs2: "" } },
                          [
                            _vm._v(" LOGIN PAGE IMAGE "),
                            image
                              ? _c("img", {
                                  staticClass: "rtb-max-w-full",
                                  attrs: { src: image, alt: "Image" }
                                })
                              : _vm._e(),
                            _c("image-uploader", {
                              attrs: {
                                debug: 1,
                                "max-width": _vm.CLIENT_IMAGES_MAX_WIDTH,
                                "auto-rotate": "",
                                preview: false,
                                "class-name": [
                                  "fileinput",
                                  { "fileinput--loaded": image }
                                ],
                                "output-format": "verbose",
                                capture: "environment",
                                accept: ".jpg, .png, .jpeg"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updateImages($event, index)
                                }
                              }
                            }),
                            image
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-0",
                                    attrs: {
                                      color: "error",
                                      depressed: "",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.imagesDeleteHandler(index)
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { loading: _vm.loadingImage, flat: "" },
                          on: { click: _vm.update }
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.tab == "MATCH_EMAILS"
            ? _c(
                "v-flex",
                {
                  staticClass: "scrollable",
                  attrs: { "pa-4": "", "d-flex": "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "d-flex": "" } },
                        [
                          _c("ClientSettingMatchEmails", {
                            model: {
                              value: _vm.object.matchEmails,
                              callback: function($$v) {
                                _vm.$set(_vm.object, "matchEmails", $$v)
                              },
                              expression: "object.matchEmails"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-center": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    loading: _vm.loadingImage,
                                    flat: ""
                                  },
                                  on: { click: _vm.update }
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secondary",
                                  attrs: { flat: "" },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("Cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.tab == "ACTIONS"
            ? _c(
                "v-flex",
                { key: "actions" },
                [_c("ClientSettingActionsTabpanel")],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }