<template>
  <v-dialog ref="dialog" width="290px">
    <template v-slot:activator="{ on }">
      <v-flex class="tournament-date-container">
        <svg-icon name="calendar" class="rtb-color-white calendar" />
        <v-text-field
          class="tournament-date"
          :value="game.getTime(type)"
          box
          readonly
          :disabled="!isBeingEdited"
          hide-details
          v-on="on"
          align-self-end
        />
      </v-flex>
    </template>
    <fieldset>
      <legend class="sr-only">Game Date/Time fields</legend>
      <v-date-picker
        :value="game.getDate(type)"
        scrollable
        @input="$emit('onSetDate', $event)"
      >
      </v-date-picker>
      <v-sheet class="rtb-d-flex items-center">
        <v-select
          :value="game.getHours(type)"
          :items="Timer.hours"
          label="Hours"
          hide-details
          @input="$emit('onSetHours', $event)"
        ></v-select>
        <span class="mx-2">:</span>
        <v-select
          :value="game.getMinutes()"
          :items="Timer.minutes"
          label="Minutes"
          hide-details
          @input="$emit('onSetMinutes', $event)"
        ></v-select>
      </v-sheet>
    </fieldset>
  </v-dialog>
</template>

<script>
import { START } from "./entities/TournamentGame"
import { Timer } from "@/helpers"

export default {
  name: "TournamentDateTimePicker",
  props: {
    isBeingEdited: {
      type: Boolean
    },
    game: {
      type: Object
    },
    type: {
      type: String,
      default: START
    }
  },
  data: () => ({
    Timer
  })
}
</script>

<style scoped></style>
