var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        staticClass: "max-w-xs",
        attrs: { items: _vm.themeOptions, label: "Theme", "hide-details": "" },
        model: {
          value: _vm.themeID,
          callback: function($$v) {
            _vm.themeID = $$v
          },
          expression: "themeID"
        }
      }),
      _vm.client.themeID
        ? _c(
            "v-btn",
            {
              attrs: { color: "red", dark: "" },
              on: { click: _vm.deleteClientTheme }
            },
            [
              _vm._v("Reset "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("refresh")])
            ],
            1
          )
        : _vm._e(),
      _vm.canSave
        ? _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.saveClientTheme } },
            [
              _vm._v("Save "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("save")])
            ],
            1
          )
        : _vm._e(),
      _vm.theme
        ? _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: { click: _vm.showThemeCustomizerModal }
            },
            [
              _vm._v("Edit "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        { attrs: { color: "green", dark: "" }, on: { click: _vm.createTheme } },
        [
          _vm._v("Create "),
          _c("v-icon", { attrs: { right: "" } }, [_vm._v("add")])
        ],
        1
      ),
      _c(
        "v-btn",
        { attrs: { color: "red", dark: "" }, on: { click: _vm.purgeGames } },
        [
          _vm._v("Purge games "),
          _c("v-icon", { attrs: { right: "" } }, [_vm._v("refresh")])
        ],
        1
      ),
      _vm.theme
        ? _c("ThemePreview", { attrs: { theme: _vm.theme } })
        : _vm._e(),
      _vm.themeToCreate || _vm.theme
        ? _c("ThemeCustomizerModal", {
            staticClass: "mt-4",
            attrs: { theme: _vm.themeToCreate || _vm.theme },
            on: { save: _vm.onThemeSave, delete: _vm.onThemeDelete },
            model: {
              value: _vm.isThemeCustomizerModalVisible,
              callback: function($$v) {
                _vm.isThemeCustomizerModalVisible = $$v
              },
              expression: "isThemeCustomizerModalVisible"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }