<template>
  <fieldset>
    <legend>{{ label }}</legend>
    <template v-if="model">
      <img :src="model" alt="Logo" width="128" class="mb-2" />
      <v-text-field v-model="model" :label="label" />
    </template>
    <image-uploader @input="onUploaderInput" :preview="false" />
  </fieldset>
</template>

<script>
import Vue from "vue"
export default Vue.extend({
  name: "ThemeImage",
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    onUploaderInput(e) {
      this.$emit("upload", e)
    }
  }
})
</script>
