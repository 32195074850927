var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.clientHasTheme
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                color: "primary",
                                depressed: "",
                                loading: _vm.isLoading
                              },
                              on: { click: _vm.copyClientTheme }
                            },
                            on
                          ),
                          [_vm._v(" Copy Theme ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                667572715
              )
            },
            [_c("span", [_vm._v("Copy the Client theme to all games. ")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }