var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.localTheme
    ? _c(
        "v-card",
        { staticClass: "theme-customizer" },
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v("Theme Customizer")
          ]),
          _c("v-card-text", [
            _c(
              "fieldset",
              [
                _c("legend", [_vm._v("Colors")]),
                _c("StylesEditor", {
                  staticClass: "mt-1",
                  attrs: { styles: _vm.localTheme.styles },
                  on: { input: _vm.onStylesInput }
                })
              ],
              1
            ),
            _c(
              "fieldset",
              [
                _c("legend", [_vm._v("Images")]),
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { attrs: { "xs-3": "" } },
                      [
                        _c("ThemeImage", {
                          attrs: { label: "Top Left Image" },
                          on: {
                            upload: function($event) {
                              return _vm.onThemeImageUpload($event, "logo")
                            }
                          },
                          model: {
                            value: _vm.localTheme.images.logo,
                            callback: function($$v) {
                              _vm.$set(_vm.localTheme.images, "logo", $$v)
                            },
                            expression: "localTheme.images.logo"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { "xs-3": "" } },
                      [
                        _c("ThemeImage", {
                          attrs: { label: "Expo Custom Logo" },
                          on: {
                            upload: function($event) {
                              return _vm.onThemeImageUpload($event, "expoLogo")
                            }
                          },
                          model: {
                            value: _vm.localTheme.images.expoLogo,
                            callback: function($$v) {
                              _vm.$set(_vm.localTheme.images, "expoLogo", $$v)
                            },
                            expression: "localTheme.images.expoLogo"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { "xs-3": "" } },
                      [
                        _c("ThemeImage", {
                          attrs: { label: "Login Logo" },
                          on: {
                            upload: function($event) {
                              return _vm.onThemeImageUpload($event, "loginLogo")
                            }
                          },
                          model: {
                            value: _vm.localTheme.images.loginLogo,
                            callback: function($$v) {
                              _vm.$set(_vm.localTheme.images, "loginLogo", $$v)
                            },
                            expression: "localTheme.images.loginLogo"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  _vm._l(_vm.localTheme.images.loginCarousel, function(
                    image,
                    index
                  ) {
                    return _c(
                      "v-flex",
                      { key: index, attrs: { xs3: "" } },
                      [
                        _c("ThemeImage", {
                          attrs: { label: "Login Page Image" },
                          on: {
                            upload: function($event) {
                              return _vm.onThemeImageUpload(
                                $event,
                                "loginCarousel",
                                index
                              )
                            }
                          },
                          model: {
                            value: _vm.localTheme.images.loginCarousel[index],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.localTheme.images.loginCarousel,
                                index,
                                $$v
                              )
                            },
                            expression: "localTheme.images.loginCarousel[index]"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "fieldset",
              [
                _c("legend", [_vm._v("Meta")]),
                _c(
                  "v-layout",
                  { attrs: { "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs2: "" } },
                      [
                        _c("v-switch", {
                          attrs: { label: "Hide Go Game" },
                          model: {
                            value: _vm.localTheme.meta.hideGoGame,
                            callback: function($$v) {
                              _vm.$set(_vm.localTheme.meta, "hideGoGame", $$v)
                            },
                            expression: "localTheme.meta.hideGoGame"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs2: "" } },
                      [
                        _c("v-switch", {
                          attrs: { label: "Hide Expo Text" },
                          model: {
                            value: _vm.localTheme.meta.hideExpoText,
                            callback: function($$v) {
                              _vm.$set(_vm.localTheme.meta, "hideExpoText", $$v)
                            },
                            expression: "localTheme.meta.hideExpoText"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs2: "" } },
                      [
                        _c("v-text-field", {
                          attrs: { label: "Tagline", "hide-details": "" },
                          model: {
                            value: _vm.localTheme.meta.tagline,
                            callback: function($$v) {
                              _vm.$set(_vm.localTheme.meta, "tagline", $$v)
                            },
                            expression: "localTheme.meta.tagline"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-text-field", {
                staticClass: "max-w-sm",
                attrs: { "hide-details": "" },
                model: {
                  value: _vm.localTheme.name,
                  callback: function($$v) {
                    _vm.$set(_vm.localTheme, "name", $$v)
                  },
                  expression: "localTheme.name"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "red" }, on: { click: _vm.deleteTheme } },
                [_vm._v("Delete")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.onSave } },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }