var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(_vm._s(_vm.label))]),
      _vm.model
        ? [
            _c("img", {
              staticClass: "mb-2",
              attrs: { src: _vm.model, alt: "Logo", width: "128" }
            }),
            _c("v-text-field", {
              attrs: { label: _vm.label },
              model: {
                value: _vm.model,
                callback: function($$v) {
                  _vm.model = $$v
                },
                expression: "model"
              }
            })
          ]
        : _vm._e(),
      _c("image-uploader", {
        attrs: { preview: false },
        on: { input: _vm.onUploaderInput }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }