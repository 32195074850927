<template>
  <div>
    <v-select
      v-model="themeID"
      :items="themeOptions"
      label="Theme"
      class="max-w-xs"
      hide-details
    ></v-select>
    <v-btn v-if="client.themeID" color="red" dark @click="deleteClientTheme"
      >Reset <v-icon right>refresh</v-icon></v-btn
    >
    <v-btn v-if="canSave" color="primary" @click="saveClientTheme"
      >Save <v-icon right>save</v-icon></v-btn
    >
    <v-btn v-if="theme" color="primary" @click="showThemeCustomizerModal"
      >Edit <v-icon right>edit</v-icon></v-btn
    >
    <v-btn color="green" dark @click="createTheme"
      >Create <v-icon right>add</v-icon></v-btn
    >
    <v-btn color="red" dark @click="purgeGames"
      >Purge games <v-icon right>refresh</v-icon></v-btn
    >
    <ThemePreview v-if="theme" :theme="theme" />
    <ThemeCustomizerModal
      v-if="themeToCreate || theme"
      v-model="isThemeCustomizerModalVisible"
      :theme="themeToCreate || theme"
      class="mt-4"
      @save="onThemeSave"
      @delete="onThemeDelete"
    />
  </div>
</template>

<script>
import Vue from "vue"
import { omit } from "lodash"

import { ThemeService } from "@/modules/theme/services"

import { MODULE_NAME as THEME_MODULE_NAME } from "@/modules/theme/store"
import { ActionTypes as ThemeModuleActionTypes } from "@/modules/theme/store/action-types"

import ThemeCustomizerModal from "@/modules/theme/components/ThemeCustomizerModal.vue"
import ThemePreview from "@/modules/theme/components/ThemePreview.vue"

import { Firebase, SelectManager } from "@/helpers"
import { ThemeFactory } from "@/modules/theme/entities/ThemeFactory"

export default Vue.extend({
  name: "ClientSettingsStylesTabpanel",
  components: {
    ThemeCustomizerModal,
    ThemePreview
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isThemeCustomizerModalVisible: false,
      themeID: this.client.themeID,
      themeToCreate: null
    }
  },
  computed: {
    org() {
      return this.$store.getters.theOrg
    },
    orgID() {
      return this.$store.getters.orgID
    },
    themes() {
      return this.org.themes
        ? Firebase.normalizeSnapshotToArray(this.org.themes)
        : []
    },
    theme() {
      return this.themes.find(theme => theme.id === this.themeID)
    },
    themeOptions() {
      return this.org.themes
        ? SelectManager.normalize(this.org.themes, "name")
        : []
    },
    /** @returns {boolean} */
    canSave() {
      return this.themeID !== this.client.themeID
    }
  },
  methods: {
    showThemeCustomizerModal() {
      this.isThemeCustomizerModalVisible = true
    },
    /** @param {import('@/modules/theme/components/types').SaveEventPayload} payload */
    onThemeSave(payload) {
      this.themeToCreate = null
      let { themeID, value } = payload

      this.$store.dispatch(
        `${THEME_MODULE_NAME}/${ThemeModuleActionTypes.UPDATE_ORG_THEME}`,
        {
          orgID: this.orgID,
          themeID,
          value: omit(value, "id")
        }
      )
    },
    /** @param {import('../Theme/typedef').DeleteThemePayload} payload */
    onThemeDelete(payload) {
      const { themeID } = payload
      this.$store.dispatch(
        `${THEME_MODULE_NAME}/${ThemeModuleActionTypes.DELETE_ORG_THEME}`,
        { orgID: this.orgID, themeID }
      )
    },
    createTheme() {
      this.themeToCreate = ThemeFactory.create()
      this.showThemeCustomizerModal()
    },
    saveClientTheme() {
      this.$emit("input:theme", { themeID: this.themeID })
    },
    deleteClientTheme() {
      this.$emit("input:theme", { themeID: null })
      this.themeID = undefined
    },
    purgeGames() {
      ThemeService.purgeGames(this.orgID, this.$store.state.auth.clientID)
    }
  }
})
</script>
