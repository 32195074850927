var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "client-match-emails", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                attrs: { label: "Match Users Emails" },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.emailList,
                  callback: function($$v) {
                    _vm.emailList = $$v
                  },
                  expression: "emailList"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }