<template>
  <v-container fluid class="client-match-emails">
    <v-layout column>
      <v-flex xs12>
        <v-textarea
          v-model="emailList"
          label="Match Users Emails"
          @change="onChange"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ClientSettingMatchEmails",
  props: ["value"],
  data: () => ({
    emailList: ""
  }),
  created() {
    this.setEmailList(this.value)
  },
  methods: {
    setEmailList(listArray) {
      if (Array.isArray(listArray)) {
        this.emailList = listArray.join(", ")
      } else {
        this.emailList = ""
      }
    },
    onChange() {
      // Split by not email characters
      let listArray = this.emailList
        .split(/[\s,;]+/)
        .filter(item => !!item)
        .map(item => item.toLowerCase())
      this.setEmailList(listArray)
      this.$emit("input", listArray)
    }
  }
}
</script>
