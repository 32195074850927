<template>
  <v-flex d-flex class="tournament-user-manager">
    <v-layout column>
      <v-flex v-if="available && !!available.length">
        <v-select
          slot="header"
          :items="available"
          label="ADD USER"
          hide-details
          dense
          @change="addUsers"
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex mt-2>
        <v-layout row>
          <v-flex
            d-flex
            v-for="(users, priority) in usersGroupedByPriority"
            :key="`tournament-priority-group-${priority}`"
          >
            <v-layout column>
              <v-flex shrink class="text-xs-center">
                PRIORITY LEVEL {{ priority }}
              </v-flex>
              <v-flex d-flex>
                <draggable
                  :list="users"
                  :disabled="drag"
                  v-bind="{ group: 'people', animation: 150 }"
                  @change="onChange($event, priority)"
                  class="layout column"
                >
                  <v-flex
                    shrink
                    v-for="user in users"
                    :key="`tournament-user-${user.id}`"
                    :data-uuid="user.id"
                    v-show="user.id.indexOf('TEMP') < 0"
                    class="tournament-user-manager__user"
                  >
                    <span class="tournament-user-manager__user__name">
                      {{ user.firstname }} {{ user.lastname }}
                    </span>
                    <v-icon
                      class="tournament-user-manager__user__delete-icon"
                      @click="deleteUser(user.id)"
                      >delete</v-icon
                    >
                  </v-flex>
                </draggable>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink d-flex align-center>
            <v-btn circle small fab @click="addPriorityLevel">
              <v-icon>add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex mt-2 text-xs-center>
        <v-btn
          v-if="changed"
          flat
          class="success"
          @click="submit"
          :disabled="false"
          :loading="working"
        >
          SUBMIT
        </v-btn>
        <v-btn
          v-if="changed"
          flat
          class="warning"
          @click="discard"
          :disabled="working"
        >
          DISCARD
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import firebase from "firebase/app"
import "firebase/database"
import draggable from "vuedraggable"
import _ from "lodash"

export default {
  name: "TournamentHostManager",
  components: {
    draggable
  },
  props: {
    tournament: {
      type: Object,
      required: true
    },
    tournamentID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      adding: false,
      drag: false,
      users: null,
      usersOriginal: null,
      working: false
    }
  },
  created() {
    this.$store.dispatch("fetchHosts")
    this.fetch()
  },
  methods: {
    deleteUser(userID) {
      const users = { ...this.users }
      delete users[userID]
      this.users = users
    },
    async submit() {
      this.working = true
      const users = _.pickBy(this.users, (_, key) => key.indexOf("TEMP") < 0)
      await firebase
        .database()
        .ref(`client/${this.clientID}/tournaments/${this.tournamentID}/users`)
        .set(users)
      await this.fetch()
      this.working = false
    },
    async fetch() {
      const snapshot = await firebase
        .database()
        .ref(`client/${this.clientID}/tournaments/${this.tournamentID}/users`)
        .once("value")
      this.usersOriginal = snapshot.val()
      this.users = JSON.parse(JSON.stringify(this.usersOriginal))
    },
    addPriorityLevel() {
      const n = Object.keys(this.usersGroupedByPriority || {}).length + 1
      const users = { ...this.users }
      users[`TEMP_${n}`] = n
      this.users = users
    },
    addUsers(userID) {
      const [key] = Object.keys(this.usersGroupedByPriority || {})
      const users = { ...this.users }
      users[userID] = parseInt(key) || 0
      this.users = users
    },
    onChange({ added }, priority) {
      if (added && added.element) {
        const users = { ...this.users }
        users[added.element.id] = parseInt(priority) || 0
        this.users = users
      }
    },
    discard() {
      this.users = JSON.parse(JSON.stringify(this.usersOriginal))
    }
  },
  computed: {
    ...mapGetters(["hosts"]),
    ...mapGetters("auth", ["clientID"]),
    changed() {
      return JSON.stringify(this.users) != JSON.stringify(this.usersOriginal)
    },
    available() {
      return Object.entries(this.hosts || {})
        .map(([id, user]) => ({ ...user, id }))
        .filter(
          ({ deleted, clientID, id }) =>
            !deleted &&
            clientID == this.clientID &&
            this.users &&
            !this.users[id]
        )
    },
    usersGroupedByPriority() {
      return Object.entries(this.users || {})
        .map(([id, priority]) => ({ id, priority }))
        .reduce((acc, val) => {
          const hosts = this.hosts || {}
          if (acc[val.priority]) {
            acc[val.priority].push({
              ...(hosts[val.id] || {}),
              id: val.id
            })
          } else {
            acc[val.priority] = [
              {
                ...(hosts[val.id] || {}),
                id: val.id
              }
            ]
          }
          return acc
        }, {})
    }
  }
}
</script>

<style lang="scss">
.tournament-user-manager {
  &__user {
    cursor: pointer;
    margin: 5px 20px;
    padding: 5px 20px;
    border-radius: 3px;
    border: 1px solid $primary_accent_color;
    &__name,
    &__delete-icon {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
