var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      ref: "dialog",
      attrs: { width: "290px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-flex",
                { staticClass: "tournament-date-container" },
                [
                  _c("svg-icon", {
                    staticClass: "rtb-color-white calendar",
                    attrs: { name: "calendar" }
                  }),
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        staticClass: "tournament-date",
                        attrs: {
                          value: _vm.game.getTime(_vm.type),
                          box: "",
                          readonly: "",
                          disabled: !_vm.isBeingEdited,
                          "hide-details": "",
                          "align-self-end": ""
                        }
                      },
                      on
                    )
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "sr-only" }, [
            _vm._v("Game Date/Time fields")
          ]),
          _c("v-date-picker", {
            attrs: { value: _vm.game.getDate(_vm.type), scrollable: "" },
            on: {
              input: function($event) {
                return _vm.$emit("onSetDate", $event)
              }
            }
          }),
          _c(
            "v-sheet",
            { staticClass: "rtb-d-flex items-center" },
            [
              _c("v-select", {
                attrs: {
                  value: _vm.game.getHours(_vm.type),
                  items: _vm.Timer.hours,
                  label: "Hours",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("onSetHours", $event)
                  }
                }
              }),
              _c("span", { staticClass: "mx-2" }, [_vm._v(":")]),
              _c("v-select", {
                attrs: {
                  value: _vm.game.getMinutes(),
                  items: _vm.Timer.minutes,
                  label: "Minutes",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("onSetMinutes", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }