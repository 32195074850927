<template>
  <v-flex d-flex class="client-game-settings">
    <v-layout column wrap>
      <v-layout row>
        <v-flex xs1 d-flex align-center>
          <v-progress-circular
            v-if="!!initializing"
            indeterminate
            size="24"
            class="spinner"
          />
          <v-btn v-else icon @click="onSelect">
            <v-icon>flight_takeoff</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs3 d-flex align-center>
          <v-text-field :label="name" v-model="externalName" />
        </v-flex>
        <v-flex style="width: 50px" d-flex align-center mx-2>
          <v-text-field
            v-if="!game.tournamentID"
            v-model="round"
            label="Round"
          />
        </v-flex>
        <v-flex style="margin-top: -5px;width: 70px" d-flex align-center mx-2>
          <div @click="setTime()">
            <b>{{ startTime }}</b>
          </div>
        </v-flex>
        <v-flex style="width: 70px" d-flex align-center mx-2>
          <v-text-field v-model="duration" label="Duration" />
        </v-flex>
        <v-flex style="width: 70px" mx-2>
          <v-switch dense v-model="deactivate" label="Off" />
        </v-flex>
        <v-flex style="width: 85px" mx-2>
          <v-switch v-model="ondeck" label="Stage" />
        </v-flex>
        <v-flex style="width: 85px" mx-2>
          <v-switch v-model="auditable" label="Audit" />
        </v-flex>
        <v-flex style="width: 125px">
          <v-switch
            dense
            v-model="defaultKeynote"
            label="Kenote"
            :disabled="isKeynoteDisabled"
          />
        </v-flex>
        <v-flex xs2 mr-2>
          <v-select
            hide-details
            dense
            :items="userList"
            v-model="userID"
            label="Host"
            item-text="fullname"
            item-value="id"
          />
        </v-flex>
        <v-flex xs2 mr-2>
          <v-select v-model="gameType" :items="gameTypes" label="Game Type" />
        </v-flex>
        <v-flex xs1 mr-2>
          <v-btn class="error" icon @click="onDelete">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-dialog v-if="editTime" width="60%" class="game-settings-wrap">
      Edit Time
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapActions } from "vuex"
const KEYNOTE_TYPES = ["YouTube", "Twitch", "Standard"]

export default {
  name: "ClientSettingGame",
  data() {
    return {
      editTime: false
    }
  },
  props: {
    initializing: {
      type: Boolean
    },
    position: {
      type: Number,
      required: true
    },
    game: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    async onDelete() {
      if (confirm(`Delete game ${this.game.externalName || this.game.name}?`)) {
        await this.updateGameAny({
          theKey: this.gameID,
          deletedTimestamp: Date.now()
        })
        this.$emit("update")
      }
    },
    onSelect() {
      this.$emit("onSelect")
    }
  },
  computed: {
    startTime() {
      const date = new Date(this.game.startTimestamp)
      const hours = ("0" + date.getHours()).substr(-2)
      const month = date.getMonth() + 1
      const day = ("0" + date.getDate()).substr(-2)
      const minutes = ("0" + date.getMinutes()).substr(-2)
      const seconds = ("0" + date.getSeconds()).substr(-2)
      this.endTime = {
        HH: hours,
        mm: minutes,
        ss: seconds
      }
      // c console.log("THE TIME", this.game.startTimestamp)
      return month + "/" + day + " " + hours + ":" + minutes
    },
    gameTypes() {
      return this.$store.getters["Games/gameTypes"]
    },
    gameID() {
      return this.game.theKey
    },
    userList() {
      var arr = Object.values(this.users)
      for (var i in arr) {
        arr[i].fullname = arr[i].firstname + " " + arr[i].lastname
      }
      // console.log("USERLSIT", arr)
      return arr
    },
    isKeynoteDisabled() {
      return !KEYNOTE_TYPES.includes(this.game.gameType)
    },
    ondeck: {
      get() {
        return this.game.ondeck
      },
      async set(value) {
        await this.updateGameAny({ theKey: this.gameID, ondeck: value })
        this.$emit("update")
      }
    },
    gameType: {
      get() {
        return this.game.gameType
      },
      async set(value) {
        let data = { theKey: this.gameID, gameType: value }
        if (!KEYNOTE_TYPES.includes(value)) data["defaultKeynote"] = false
        await this.updateGameAny(data)
        this.$emit("update")
      }
    },
    externalName: {
      get() {
        return this.game.externalName
      },
      async set(value) {
        await this.updateGameAny({ theKey: this.gameID, externalName: value })
        this.$emit("update")
      }
    },
    name: {
      get() {
        return this.game.name
      }
    },
    auditable: {
      get() {
        return this.game.auditable
      },
      async set(value) {
        await this.updateGameAny({ theKey: this.gameID, auditable: value })
        this.$emit("update")
      }
    },
    deactivate: {
      get() {
        return this.game.deactivate
      },
      async set(value) {
        await this.updateGameAny({ theKey: this.gameID, deactivate: value })
        this.$emit("update")
      }
    },
    round: {
      get() {
        return this.game.round
      },
      async set(value) {
        await this.updateGameAny({ theKey: this.gameID, round: value })
        this.$emit("update")
      }
    },
    duration: {
      get() {
        return this.game.duration / 60000
      },
      async set(value) {
        await this.updateGameAny({
          theKey: this.gameID,
          duration: value * 60000
        })
        this.$emit("update")
      }
    },
    userID: {
      get() {
        return this.game.hostUserID
      },
      async set(value) {
        await this.updateGameAny({ theKey: this.gameID, hostUserID: value })
        this.$emit("update")
      }
    },
    defaultKeynote: {
      get() {
        return this.game.defaultKeynote
      },
      async set(value) {
        await this.updateGameAny({ theKey: this.gameID, defaultKeynote: value })
        if (value) {
          this.$emit("changeDefaultKeynote", { theKey: this.gameID })
        } else {
          this.$emit("update")
        }
      }
    }
  }
}
</script>

<style lang="scss">
.client-game-settings {
  .spinner {
    color: $primary_accent_color !important;
    opacity: 0.5;
  }
}
</style>
