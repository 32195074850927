var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "client-game-settings", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs1: "", "d-flex": "", "align-center": "" } },
                [
                  !!_vm.initializing
                    ? _c("v-progress-circular", {
                        staticClass: "spinner",
                        attrs: { indeterminate: "", size: "24" }
                      })
                    : _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.onSelect } },
                        [_c("v-icon", [_vm._v("flight_takeoff")])],
                        1
                      )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs3: "", "d-flex": "", "align-center": "" } },
                [
                  _c("v-text-field", {
                    attrs: { label: _vm.name },
                    model: {
                      value: _vm.externalName,
                      callback: function($$v) {
                        _vm.externalName = $$v
                      },
                      expression: "externalName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticStyle: { width: "50px" },
                  attrs: { "d-flex": "", "align-center": "", "mx-2": "" }
                },
                [
                  !_vm.game.tournamentID
                    ? _c("v-text-field", {
                        attrs: { label: "Round" },
                        model: {
                          value: _vm.round,
                          callback: function($$v) {
                            _vm.round = $$v
                          },
                          expression: "round"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticStyle: { "margin-top": "-5px", width: "70px" },
                  attrs: { "d-flex": "", "align-center": "", "mx-2": "" }
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setTime()
                        }
                      }
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.startTime))])]
                  )
                ]
              ),
              _c(
                "v-flex",
                {
                  staticStyle: { width: "70px" },
                  attrs: { "d-flex": "", "align-center": "", "mx-2": "" }
                },
                [
                  _c("v-text-field", {
                    attrs: { label: "Duration" },
                    model: {
                      value: _vm.duration,
                      callback: function($$v) {
                        _vm.duration = $$v
                      },
                      expression: "duration"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticStyle: { width: "70px" }, attrs: { "mx-2": "" } },
                [
                  _c("v-switch", {
                    attrs: { dense: "", label: "Off" },
                    model: {
                      value: _vm.deactivate,
                      callback: function($$v) {
                        _vm.deactivate = $$v
                      },
                      expression: "deactivate"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticStyle: { width: "85px" }, attrs: { "mx-2": "" } },
                [
                  _c("v-switch", {
                    attrs: { label: "Stage" },
                    model: {
                      value: _vm.ondeck,
                      callback: function($$v) {
                        _vm.ondeck = $$v
                      },
                      expression: "ondeck"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticStyle: { width: "85px" }, attrs: { "mx-2": "" } },
                [
                  _c("v-switch", {
                    attrs: { label: "Audit" },
                    model: {
                      value: _vm.auditable,
                      callback: function($$v) {
                        _vm.auditable = $$v
                      },
                      expression: "auditable"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticStyle: { width: "125px" } },
                [
                  _c("v-switch", {
                    attrs: {
                      dense: "",
                      label: "Kenote",
                      disabled: _vm.isKeynoteDisabled
                    },
                    model: {
                      value: _vm.defaultKeynote,
                      callback: function($$v) {
                        _vm.defaultKeynote = $$v
                      },
                      expression: "defaultKeynote"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs2: "", "mr-2": "" } },
                [
                  _c("v-select", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      items: _vm.userList,
                      label: "Host",
                      "item-text": "fullname",
                      "item-value": "id"
                    },
                    model: {
                      value: _vm.userID,
                      callback: function($$v) {
                        _vm.userID = $$v
                      },
                      expression: "userID"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs2: "", "mr-2": "" } },
                [
                  _c("v-select", {
                    attrs: { items: _vm.gameTypes, label: "Game Type" },
                    model: {
                      value: _vm.gameType,
                      callback: function($$v) {
                        _vm.gameType = $$v
                      },
                      expression: "gameType"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs1: "", "mr-2": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "error",
                      attrs: { icon: "" },
                      on: { click: _vm.onDelete }
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.editTime
        ? _c(
            "v-dialog",
            { staticClass: "game-settings-wrap", attrs: { width: "60%" } },
            [_vm._v(" Edit Time ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }