<template>
  <v-flex d-flex class="tournament-controls">
    <v-layout column>
      <v-flex shrink text-xs-center>
        <h2>Tournament Controls</h2>
      </v-flex>
      <v-flex shrink>
        <v-select
          v-model="currentTournamentID"
          :items="tournamentIDs"
          label="Select Tournament"
          :loading="working"
          dense
        />
      </v-flex>
      <v-flex shrink>
        <v-layout mb-3 v-if="!!next">
          <v-flex shrink d-flex align-center>
            <v-btn
              flat
              class="success"
              @click="nextRound"
              :disabled="!isRoundTime || isStarted(next.id)"
              :loading="working"
            >
              <template
                v-if="!isRoundTime && !!current && !!current.expectedEndTime"
              >
                NEXT ROUND IN
                <PreGameCountdown
                  class="tournament-controls__next-button__countdow"
                  :endTime="current.expectedEndTime"
                />
              </template>
              <template v-else-if="isStarted(next.id)"> STARTED </template>
              <template v-else> NEXT ROUND </template>
            </v-btn>
          </v-flex>
          <v-flex
            shrink
            d-flex
            align-center
            v-if="!!next && isStarted(next.id)"
          >
            <v-btn flat class="warning" @click="resetRound" :loading="working">
              RESET
            </v-btn>
          </v-flex>
          <template v-if="!isStarted(next.id)">
            <v-flex shrink d-flex align-center>
              <v-btn
                flat
                class="warning"
                @click="forceNextRound"
                :loading="working"
              >
                FORCE NEXT ROUND
              </v-btn>
            </v-flex>
            <template v-if="preLastRoundID === currentGameID">
              <v-flex d-flex align-center shrink pr-2> Truncate To Top </v-flex>
              <v-flex d-flex align-center shrink>
                <v-text-field
                  v-model.number="truncateTo"
                  label="#"
                  :loading="working"
                  :disabled="loserTolerant"
                  hide-details
                />
              </v-flex>
            </template>
            <v-flex d-flex align-center shrink>
              <v-checkbox
                v-model="loserTolerant"
                label="All Teams Advance"
                :disabled="!!truncateTo"
                :readonly="working"
                dense
              />
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
      <template v-if="!!tournament">
        <v-flex d-flex>
          <v-layout row>
            <template v-if="!!tournament.games">
              <v-flex
                d-flex
                v-for="game in sortedGamesMerged"
                :key="`tournament-game-${game.id}`"
              >
                <v-layout column>
                  <v-flex shrink>
                    Game ID: {{ game.id }}
                    <b v-if="game.id === currentGameID"> (Current)</b
                    ><b v-else-if="game.id === nextGameID">(Next)</b></v-flex
                  >
                  <v-flex shrink>
                    <template v-if="!!game.timestamp">
                      Starts In <PreGameCountdown :endTime="game.timestamp" />
                    </template>
                    <template v-else> Unavailable </template>
                  </v-flex>
                  <template v-if="!!game.teams">
                    <v-flex
                      v-for="(team, teamID) in game.teams"
                      :key="`tournament-game-${game.id}-team-${teamID}`"
                      d-flex
                      align-center
                      pa-2
                      :class="{
                        'tournament-controls__team-pending': !!team.pending,
                        'tournament-controls__team-winner': isWinner(
                          game.id,
                          teamID,
                          team.pending
                        )
                      }"
                    >
                      <v-layout>
                        <v-flex
                          d-flex
                          align-center
                          xs5
                          class="tournament-controls__team-name"
                        >
                          {{ team.name || "PENDING..." }}
                          <b v-if="!!team.pending">(IN PROGRESS)</b>
                        </v-flex>
                        <v-flex ml-2 xs4 class="tournament-controls__online">
                          <div
                            :class="{
                              'low-online':
                                game.id == currentGameID
                                  ? team.online < MIN_USERS_ONLINE
                                  : false
                            }"
                          >
                            Online: {{ team.online }}
                          </div>
                          <div>Offline: {{ team.offline }}</div>
                          <div>Total: {{ team.total }}</div>
                        </v-flex>
                        <v-flex xs3 ml-2 d-flex align-center>
                          Score: {{ team.score }}
                        </v-flex>
                        <v-flex xs3 ml-2 d-flex align-center>
                          <v-select
                            :loading="merging"
                            :disabled="merging"
                            v-if="
                              game.id == currentGameID &&
                              team.total > 0 &&
                              !team.pending
                            "
                            :items="mergeOptions(teamID, game.id)"
                            label="MERGE TO"
                            hide-details
                            dense
                            @change="
                              value =>
                                onSelectChange({
                                  team1: {
                                    id: value,
                                    name: game.teams[value].name
                                  },
                                  team2: { id: teamID, name: team.name }
                                })
                            "
                            item-text="name"
                            item-value="id"
                          />
                          <v-select
                            :loading="merging"
                            :disabled="merging"
                            v-if="game.id == currentGameID && team.pending"
                            :items="sendOptions(team.gameID, game.id)"
                            label="SEND TEAM TO"
                            hide-details
                            dense
                            @focus="fetchClientGames"
                            @change="
                              value =>
                                onSendSelectChange({
                                  gameID: value,
                                  teamID,
                                  fromGameID: team.gameID
                                })
                            "
                            item-text="name"
                            item-value="id"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
      </template>
      <template v-if="!!tournament">
        <v-flex shrink text-xs-center>
          <h2>User Manager</h2>
        </v-flex>
        <v-flex d-flex>
          <TournamentHostManager
            :tournamentID="currentTournamentID"
            :tournament="tournament"
          />
        </v-flex>
      </template>
    </v-layout>
  </v-flex>
</template>

<script>
import TournamentHostManager from "@/components/Tournament/TournamentHostManager"
import { mapGetters, mapActions } from "vuex"
import PreGameCountdown from "@/components/PreGameCountdown"
import { TournamentService } from "@/services/tournament/tournament.service"
import {
  approximateRound,
  NUMBER_OF_TEAMS_PER_GAME,
  MIN_NUMBER_OF_TEAMS_PER_GAME
} from "@/helpers/tournament"
import { fetchGames } from "@/services/game.service"
import _ from "lodash"
import shuffle from "array-shuffle"
import firebase from "firebase/app"
import "firebase/database"
import { Role } from "@/helpers"
import { Event } from "./event"
import { fetchGameTeamsObject } from "@/services/game.service"

const USERS_UPDATE_INTERVAL = 10000
const MIN_USERS_ONLINE = 1

const TEAM_MERGE_WARNING_TEMPLATE = (name1, name2) =>
  `You sure you want to merge team ${name1} into ${name2}? This action is irreversible`

const MESSAGE_TEMPLATE = n =>
  `There are ${n} incomplete games. Do you want to end those games?`

const HOST_WARNING_MESSAGE_TEMPLATE = (numberOfGames, numberOfHosts) =>
  `You don't have enought available hosts to run this round. Games: ${numberOfGames} Hosts: ${numberOfHosts}`

const FORCE_WARNING_MESSAGE = `This action will change the next round start time. Continue?`

const START_STATUS_WARNING_MESSAGE = `You will have to start this round again. Continue?`

export default {
  name: "TournamentControls",
  components: {
    PreGameCountdown,
    TournamentHostManager
  },
  data() {
    return {
      truncateTo: null,
      loserTolerant: false,
      merging: false,
      MIN_USERS_ONLINE: MIN_USERS_ONLINE,
      working: false,
      isRoundTime: false,
      current: null,
      next: null,
      currentTournamentID: null,
      tournamentSubscription: null,
      tournaments: {},
      tournament: null,
      usersUpdateInterval: null,
      teamsUpdateInterval: null,
      teamsToMergeIn: null,
      clientGames: [],
      timeUpdateInterval: null
    }
  },
  async created() {
    const tournaments = await TournamentService.fetchTournamentsByClientID(
      this.clientID
    )
    this.tournaments = _.pickBy(tournaments, (_, key) => parseInt(key) !== 0)
    const [tournamentID] = Object.keys(this.tournaments || {})
    if (tournamentID != 0) {
      this.currentTournamentID = tournamentID
    }
    this.$store.dispatch("allusers/fetchUsers", {
      clientID: this.clientID
    })
    this.working = true
    await this.fetchClientUsers()
    await this.fetchRoundTeams()
    this.updateTime()
    this.working = false
    this.usersUpdateInterval = setInterval(
      this.fetchClientUsers,
      USERS_UPDATE_INTERVAL
    )
    this.timeUpdateInterval = setInterval(this.updateTime, 1000)
    this.teamsUpdateInterval = setInterval(this.fetchRoundTeams, 5000)
    this.$bus.$on(Event.TOURNAMENTS_UPDATE, this.onTournamentsUpdate)
  },
  beforeDestroy() {
    if (this.tournamentSubscription)
      this.tournamentSubscription.off("value", this.onTournamentUpdate)
    clearInterval(this.usersUpdateInterval)
    clearInterval(this.teamsUpdateInterval)
    clearInterval(this.timeUpdateInterval)
    this.$bus.$off(Event.TOURNAMENTS_UPDATE, this.onTournamentsUpdate)
  },
  computed: {
    ...mapGetters({ globalTime: "time" }),
    ...mapGetters("allusers", ["users"]),
    ...mapGetters("auth", ["clientID"]),
    ...mapGetters(["orgID"]),
    availableClientGames() {
      return this.clientGames.filter(
        game => game.tournamentID && !game.deletedTimestamp
      )
    },
    tournamentIDs() {
      return Object.keys(this.tournaments || {})
    },
    usersOnline() {
      return _.pickBy(this.users, value => value.status === "online")
    },
    usersGroupedByTeamID() {
      return Object.keys(this.users).reduce((acc, val) => {
        if (acc[this.users[val].teamID]) {
          acc[this.users[val].teamID].push(this.users[val])
        } else {
          acc[this.users[val].teamID] = [this.users[val]]
        }
        return acc
      }, {})
    },
    tournamentGames() {
      return this.tournament && this.tournament.games
        ? this.tournament.games
        : null
    },
    sortedGamesMerged() {
      const teamIDs = Object.keys(this.teamsToMergeIn || {})
      return this.sortedGames.map(game => {
        if (game.id === this.currentGameID) {
          const teams = game.teams ? { ...game.teams } : {}

          teamIDs.forEach(teamID => {
            if (!teams[teamID] && this.teamsToMergeIn[teamID]) {
              teams[teamID] = {
                ...this.teamsToMergeIn[teamID],
                pending: true
              }
            }
          })

          return {
            ...game,
            teams: Object.entries(teams)
              .map(entry => {
                const users = this.usersGroupedByTeamID[entry[0]] || []
                const usersOnline = users.filter(
                  user => user.status === "online"
                )
                return {
                  ...entry[1],
                  id: entry[0],
                  online: usersOnline.length,
                  offline: users.length - usersOnline.length,
                  total: users.length
                }
              })
              .reduce((acc, val) => {
                acc[val.id] = val
                return acc
              }, {})
          }
        } else {
          return game
        }
      })
    },
    preLastRoundID() {
      return this.sortedGames[this.sortedGames.length - 2]?.id
    },
    sortedGames() {
      return Object.entries(this.tournamentGames || {})
        .map(([id, game]) => ({ ...game, id }))
        .sort((a, b) => a.timestamp - b.timestamp)
    },
    nextGameID() {
      return this.next ? this.next.id : null
    },
    currentGameID() {
      return this.current ? this.current.id : null
    }
  },
  watch: {
    currentTournamentID: {
      handler(tournamentID) {
        if (tournamentID) {
          if (this.tournamentSubscription) {
            this.tournamentSubscription.off("value", this.onTournamentUpdate)
          }
          this.tournamentSubscription = TournamentService.getTournamentSubscription(
            this.clientID,
            tournamentID
          )
          this.tournamentSubscription.on("value", this.onTournamentUpdate)
        } else if (this.tournamentSubscription) {
          this.tournamentSubscription.off("value", this.onTournamentUpdate)
          this.tournamentSubscription = null
        }
      },
      immediate: true
    },
    globalTime: {
      handler(value) {
        const before = this.sortedGames.filter(game => game.timestamp <= value)
        const after = this.sortedGames.filter(game => game.timestamp > value)

        const current = before[before.length - 1]
        const next = after[0]

        const isRoundTime =
          next &&
          next.timestamp > value &&
          current &&
          current.expectedEndTime <= value

        if (
          (this.current && current && this.current.id !== current.id) ||
          (!this.current && current) ||
          (this.current && !current)
        ) {
          this.current = current
        }

        if (
          (this.next && next && this.next.id !== next.id) ||
          (!this.next && next) ||
          (this.next && !next)
        ) {
          this.next = next
        }

        if (this.isRoundTime !== isRoundTime) {
          this.isRoundTime = isRoundTime
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("allusers", ["fetchUsers"]),
    ...mapActions("Games", ["copyFromOriginalGame"]),
    ...mapActions({ updateGlobalTime: "updateTime" }),
    resetRound() {
      try {
        if (!confirm(START_STATUS_WARNING_MESSAGE)) throw new Error("Aborted.")
        return TournamentService.unstartTournamentRound(
          this.clientID,
          this.currentTournamentID,
          this.nextGameID
        )
      } catch (e) {
        console.error(e)
        alert(e.message)
      }
    },
    updateTime() {
      this.updateGlobalTime(Date.now())
    },
    async onSendSelectChange({ gameID, teamID, fromGameID }) {
      const snapshot = await firebase
        .database()
        .ref(`org/${this.orgID}/game/${fromGameID}/teams/${teamID}`)
        .once("value")
      const team = snapshot.val()
      if (!team) throw new Error()
      await firebase
        .database()
        .ref(`org/${this.orgID}/game/${gameID}/teams/${teamID}`)
        .set(team)

      const offlineUsersToGamesMapping = {}
      const onlineUsersToGamesMapping = {}

      const teamUsers = _.pickBy(this.users, value => value.teamID === teamID)
      const teamUsersOnline = _.pickBy(
        teamUsers,
        value => value.status === "online"
      )
      const teamUsersOffline = _.pickBy(
        teamUsers,
        value => value.status !== "online"
      )

      const teamUsersOnlineIDs = Object.keys(teamUsersOnline)
      const teamUsersOfflineIDs = Object.keys(teamUsersOffline)

      for (let j = 0; j < teamUsersOnlineIDs.length; j++) {
        onlineUsersToGamesMapping[teamUsersOnlineIDs[j]] = {
          gameID,
          force: true
        }
      }

      for (let i = 0; i < teamUsersOfflineIDs.length; i++) {
        offlineUsersToGamesMapping[`${teamUsersOfflineIDs[i]}/gameID`] = gameID
      }

      await firebase
        .database()
        .ref(`/org/1/users/`)
        .update(offlineUsersToGamesMapping)

      await firebase
        .database()
        .ref(`/client/${this.clientID}/playersToMap/`)
        .transaction(() => onlineUsersToGamesMapping)

      await firebase
        .database()
        .ref(`org/${this.orgID}/game/${fromGameID}/teams/${teamID}`)
        .set(null)

      await firebase
        .database()
        .ref(`/client/${this.clientID}/playersToMap/`)
        .transaction(() => null)
    },
    async fetchClientGames() {
      const { clientID, orgID } = this
      const games = await fetchGames({ clientID, orgID })
      // MUTATION HERE
      this.clientGames = Object.keys(games || {}).map(gameID => {
        const game = games[gameID]
        game.id = gameID
        game.name = game.externalName || game.name
        return game
      })
    },
    async fetchRoundTeams() {
      const clientGames = await fetchGames({
        clientID: this.clientID,
        orgID: this.orgID
      })
      const games = _.pickBy(
        clientGames || {},
        value =>
          value.tournamentID === this.currentTournamentID &&
          value.originalGameID === this.currentGameID
      )
      const gameIDs = Object.keys(games || {})
      const promises = gameIDs.map(gameID =>
        fetchGameTeamsObject({ orgID: this.orgID, gameID })
      )
      const groups = await Promise.all(promises)
      const teamsToMergeIn = {}

      groups.forEach((group, index) => {
        for (const teamID in group) {
          const team = group[teamID]
          if (!team || !team.name || !team.show) continue
          const originalUsers = {
            ...this.$store.getters["allusers/GET_USERS_BY_TEAM_ID"](teamID),
            ...this.$store.getters["allusers/GET_HOSTS_BY_GAME_ID"](
              gameIDs[index]
            )
          }
          const users = {}
          for (const userID in originalUsers) {
            users[userID] = {}
            users[userID].imageUrl = originalUsers[userID].image
            users[
              userID
            ].name = `${originalUsers[userID].firstname} ${originalUsers[userID].lastname}`
            users[userID].role = originalUsers[userID].role
          }
          teamsToMergeIn[teamID] = {
            name: team.name,
            score: team.totalScore,
            icon: team.icon,
            gameID: gameIDs[index],
            users
          }
        }
      })

      this.teamsToMergeIn = teamsToMergeIn
    },
    async onTournamentsUpdate() {
      this.working = true
      const tournaments = await TournamentService.fetchTournamentsByClientID(
        this.clientID
      )
      this.tournaments = _.pickBy(tournaments, (_, key) => parseInt(key) !== 0)

      if (!this.tournaments || !this.tournaments[this.currentTournamentID]) {
        this.currentTournamentID = null
      }
      if (!this.currentTournamentID) {
        const [tournamentID] = Object.keys(this.tournaments || {})
        this.currentTournamentID = tournamentID
      }
      this.working = false
    },
    async forceNextRound() {
      this.working = true
      try {
        if (!confirm(FORCE_WARNING_MESSAGE)) throw new Error("Aborted.")
        const timestamp = Date.now()
        await this.nextRound(timestamp)
        await Promise.all([
          TournamentService.updateTournamentRoundEndTime(
            this.clientID,
            this.currentTournamentID,
            this.currentGameID,
            timestamp
          ),
          TournamentService.updateTournamentRoundStartTime(
            this.clientID,
            this.currentTournamentID,
            this.nextGameID,
            timestamp
          )
        ])
      } catch (e) {
        console.error(e)
        alert(e.message)
      }

      this.working = false
    },
    isStarted(roundID) {
      return (
        this.tournament &&
        this.tournament.games &&
        this.tournament.games[roundID] &&
        this.tournament.games[roundID].status == "started"
      )
    },
    async onSelectChange({ team1, team2 }) {
      if (confirm(TEAM_MERGE_WARNING_TEMPLATE(team2.name, team1.name))) {
        this.merging = true
        await this.mergeTeamInto(team1.id, team2.id)
        this.merging = false
      }
    },
    sendOptions(fromGameID, gameID) {
      return this.availableClientGames.filter(
        game => game.id !== fromGameID && game.originalGameID === gameID
      )
    },
    mergeOptions(teamID, gameID) {
      return Object.keys(this.tournament.games[gameID].teams || {})
        .filter(id => id !== teamID)
        .map(teamID => ({
          name: this.tournament.games[gameID].teams[teamID].name,
          id: teamID
        }))
    },
    async mergeTeamInto(teamID1, teamID2) {
      await this.fetchClientUsers()
      const users = _.pickBy(this.users, value => value.teamID === teamID2)
      const usersIDs = Object.keys(users)
      const update = usersIDs.reduce((acc, val) => {
        acc[`${val}/teamID`] = teamID1
        return acc
      }, {})
      await firebase.database().ref(`org/1/users`).update(update)
      await this.fetchClientUsers()
    },
    fetchClientUsers() {
      return this.fetchUsers({ clientID: this.clientID })
    },
    onTournamentUpdate(tournament) {
      this.tournament = tournament
    },
    sliceTeams(teams, start, end) {
      const obj = {}
      teams.slice(start, end).forEach(team => {
        obj[team.id] = {
          active: true,
          can_hear_facilitator: true,
          icon: team.icon || null,
          name: team.name || null,
          show: true,
          totalScore: 0
        }
      })
      return obj
    },
    async nextRound(timestamp) {
      try {
        this.working = true
        const { clientID, orgID } = this

        if (!orgID) throw new Error("Org ID is undefined")
        if (!clientID) throw new Error("Client ID is undefined")

        const truncateToInt = parseInt(this.truncateTo)
        if (truncateToInt && isNaN(truncateToInt))
          throw new Error("Invalid input")
        if (truncateToInt === 0) throw new Error("Cannot truncate to 0")

        const clientGames = await fetchGames({ clientID, orgID })
        const incompleteRoundGameIDs = Object.entries(clientGames || {})
          .filter(
            entry =>
              entry[1].originalGameID === this.currentGameID &&
              !entry[1].endTimestamp
          )
          .map(entry => entry[0])

        const gamesTotal = Object.keys(this.tournament.games || {}).length
        const index = this.sortedGames.findIndex(
          game => game.id === this.currentGameID
        )
        const teamIDs = Object.keys(
          this.tournament.games[this.currentGameID].teams || {}
        )

        if (!teamIDs.length)
          throw new Error(`The round ${this.currentGameID} has no game data`)

        if (incompleteRoundGameIDs.length) {
          if (!confirm(MESSAGE_TEMPLATE(incompleteRoundGameIDs.length))) {
            throw new Error("Aborted.")
          }

          const update = {}
          const now = Date.now()

          incompleteRoundGameIDs.forEach(gameID => {
            update[`${gameID}/endTimestamp`] = now
          })

          await firebase.database().ref(`org/${orgID}/games`).update(update)
        }

        await this.fetchClientUsers()
        const teams = teamIDs.map(teamID => ({
          ...this.tournament.games[this.currentGameID].teams[teamID],
          id: teamID,
          winner: this.isWinner(this.currentGameID, teamID)
        }))

        let numberOfGames
        let nextRoundTeams

        if (truncateToInt) {
          const winners = teams
            .filter(team => team.winner)
            .sort((a, b) => b.score - a.score)
          const losers = teams
            .filter(team => !team.winner)
            .sort((a, b) => b.score - a.score)

          numberOfGames = 1
          nextRoundTeams = winners.concat(losers).slice(0, truncateToInt)
        } else if (this.loserTolerant) {
          numberOfGames = Math.max(
            Math.ceil(teams.length / NUMBER_OF_TEAMS_PER_GAME),
            1
          )
          nextRoundTeams = teams.slice(0)
        } else {
          const winners = teams.filter(team => team.winner)
          const losers = teams
            .filter(team => !team.winner)
            .sort((a, b) => b.score - a.score)

          const approximation = approximateRound(
            index + 1,
            gamesTotal,
            winners.length,
            losers.length
          )

          numberOfGames = approximation.numberOfGames
          nextRoundTeams = winners
            .concat(losers)
            .slice(0, approximation.numberOfTeams)
        }

        // shuffle so there are less chances to match the same teams again
        const nextRoundTeamsShuffled = shuffle(nextRoundTeams)

        const newGamesPromises = []
        const slicedTeams = []

        // break all passing teams into chunks that can be assigned to new games
        for (let i = 0; i < numberOfGames; i++) {
          slicedTeams.push(
            this.sliceTeams(
              nextRoundTeamsShuffled,
              i * NUMBER_OF_TEAMS_PER_GAME,
              i * NUMBER_OF_TEAMS_PER_GAME + NUMBER_OF_TEAMS_PER_GAME
            )
          )
        }

        // correct the case when there is a game with one team without competitors
        if (
          slicedTeams.length > 1 &&
          slicedTeams[slicedTeams.length - 1].length <
            MIN_NUMBER_OF_TEAMS_PER_GAME
        ) {
          const array1 = slicedTeams[slicedTeams.length - 2]
          const array2 = slicedTeams[slicedTeams.length - 1]
          // concat into the prev set of teams
          slicedTeams[slicedTeams.length - 2] = array1.concat(array2)
          slicedTeams.pop()
          // decrease by one game
          numberOfGames--
        }

        // the hosts must be on the priority list, online and not assigned to any game
        const priorityHostUsers = Object.entries(this.tournament.users || {})
          .map(([id, priority]) => ({ priority: parseInt(priority) || 0, id }))
          .filter(
            ({ id }) =>
              id &&
              this.users &&
              this.users[id] &&
              this.users[id].status === "online" &&
              !this.users[id].gameID
          )
          .sort((a, b) => a.priority - b.priority)

        const noPrioritHostUsers = Object.entries(this.users || {})
          .filter(
            entry =>
              entry[1].status === "online" &&
              entry[1].role === Role.Host &&
              entry[1].firstname !== "Dmitriy"
          )
          .filter(entry => priorityHostUsers.every(({ id }) => id != entry[0]))
          .map(entry => ({ id: entry[0], priority: 999 }))

        const hostUsers = priorityHostUsers.concat(noPrioritHostUsers)

        if (hostUsers.length < numberOfGames)
          alert(HOST_WARNING_MESSAGE_TEMPLATE(numberOfGames, hostUsers.length))

        await TournamentService.startTournamentRound(
          clientID,
          this.currentTournamentID,
          this.nextGameID
        )

        const name =
          this.tournament.games[this.nextGameID].label || `ROUND ${index + 2}`
        const expectedEndTime = this.tournament.games[this.nextGameID]
          .expectedEndTime
        for (let i = 0; i < numberOfGames; i++) {
          newGamesPromises.push(
            this.copyFromOriginalGame({
              originalGameID: this.nextGameID,
              name: `${name} - Game ${i + 1}`,
              round: i + 1,
              orgID,
              runStatus: "Tournament",
              gameType: "Standard",
              clientID,
              startAt: timestamp || this.next.timestamp,
              hostID: null,
              tournamentID: this.currentTournamentID,
              teams: slicedTeams[i],
              expectedEndTime
            })
          )
        }

        const gameIDs = await Promise.all(newGamesPromises)
        const gamesToTeamsMapping = {}

        // indexes slicedTeams and gameIDs are the same
        gameIDs.forEach((gameID, i) => {
          gamesToTeamsMapping[gameID] = slicedTeams[i]
        })

        const offlineUsersToGamesMapping = {}
        const onlineUsersToGamesMapping = {}

        for (let gameID in gamesToTeamsMapping) {
          const teams = gamesToTeamsMapping[gameID]
          for (let teamID in teams) {
            const teamUsers = _.pickBy(
              this.users,
              value => value.teamID === teamID
            )
            const teamUsersOnline = _.pickBy(
              teamUsers,
              value => value.status === "online"
            )
            const teamUsersOffline = _.pickBy(
              teamUsers,
              value => value.status !== "online"
            )
            const teamUsersOnlineIDs = Object.keys(teamUsersOnline)
            const teamUsersOfflineIDs = Object.keys(teamUsersOffline)

            console.log("player game ID", gameID)

            for (let j = 0; j < teamUsersOnlineIDs.length; j++) {
              onlineUsersToGamesMapping[teamUsersOnlineIDs[j]] = {
                gameID,
                force: true
              }
            }

            for (let i = 0; i < teamUsersOfflineIDs.length; i++) {
              offlineUsersToGamesMapping[
                `${teamUsersOfflineIDs[i]}/gameID`
              ] = gameID
            }
          }
        }

        // map hosts to their games taking in account priority level they are
        // assigned to and their online

        gameIDs.forEach((gameID, i) => {
          const user = hostUsers[i]
          if (!user) return
          onlineUsersToGamesMapping[user.id] = {
            gameID,
            force: true
          }
        })

        console.log("offlineUsersToGamesMapping", offlineUsersToGamesMapping)

        console.log("onlineUsersToGamesMapping", onlineUsersToGamesMapping)

        await firebase
          .database()
          .ref(`/org/1/users/`)
          .update(offlineUsersToGamesMapping)

        await firebase
          .database()
          .ref(`/client/${clientID}/playersToMap/`)
          .transaction(() => onlineUsersToGamesMapping)

        await firebase
          .database()
          .ref(`/client/${clientID}/playersToMap/`)
          .transaction(() => null)
      } catch (e) {
        console.error(e)
        alert(e.message)
      }
      this.truncateTo = null
      this.loserTolerant = false
      this.working = false
    },
    isWinner(roundID, teamID, ignore) {
      if (ignore) return false
      const gameID = this.tournament.games[roundID].teams[teamID].gameID
      const entries = Object.entries(
        this.tournament.games[roundID].teams
      ).map(([id, value]) => ({ ...value, id }))
      const sorted = entries
        .filter(value => value.gameID === gameID && value.score > 0)
        .sort((a, b) => b.score - a.score)
      const [winner] = sorted
      return winner && winner.id === teamID
    }
  }
}
</script>

<style lang="scss">
.tournament-controls {
  h2 {
    text-transform: uppercase;
  }
  &__next-button__countdow {
    display: inline-block;
    margin-left: 3px;
  }
  &__team-winner {
    background-color: $color-correct;
  }
  &__team-pending {
    background-color: rgba(39, 71, 176, 0.26);
  }
  &__online {
    .low-online {
      background-color: $color-wrong;
      color: #fff;
    }
  }
}
</style>
