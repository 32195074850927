var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "fieldset",
        [
          _c("legend", { staticClass: "sr-only" }, [
            _vm._v("Tournament " + _vm._s(_vm.tournament.id) + " fields")
          ]),
          _c(
            "v-container",
            {
              staticClass: "tournament-container",
              attrs: { fluid: "", "grid-list-md": "" }
            },
            [
              _c(
                "div",
                { staticClass: "tournament-stats" },
                [
                  _c("v-layout", { staticClass: "tournament-stats-inner" }, [
                    _c(
                      "div",
                      { staticClass: "rtb-d-flex" },
                      [
                        _c("v-text-field", {
                          staticClass: "rtb-max-w-16",
                          attrs: {
                            label: "Players count",
                            box: "",
                            disabled: !_vm.isBeingEdited || _vm.saved,
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.localTournament.playersCount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.localTournament,
                                "playersCount",
                                _vm._n($$v)
                              )
                            },
                            expression: "localTournament.playersCount"
                          }
                        }),
                        _c("v-text-field", {
                          staticClass: "rtb-max-w-16",
                          attrs: {
                            label: "Players per team",
                            box: "",
                            disabled: !_vm.isBeingEdited || _vm.saved,
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.localTournament.playersPerTeam,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.localTournament,
                                "playersPerTeam",
                                _vm._n($$v)
                              )
                            },
                            expression: "localTournament.playersPerTeam"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _vm._l(_vm.localTournament.getSortedGames(), function(
                    game,
                    index
                  ) {
                    return _c(
                      "v-flex",
                      {
                        key: "tournament-round-" + game.id,
                        staticClass: "tournament-round",
                        attrs: { xs12: "", sm4: "", md3: "", xl2: "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "rtb-d-flex" },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "tournament-column",
                                attrs: { column: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "tournament-header",
                                    attrs: { row: "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { staticClass: "round-number" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isRoundOver(game) ? "✅" : ""
                                          ) +
                                            " Round " +
                                            _vm._s(index + 1)
                                        )
                                      ]
                                    ),
                                    _c("TournamentDateTimePicker", {
                                      attrs: {
                                        type: "start",
                                        game: game,
                                        isBeingEdited: _vm.isBeingEdited
                                      },
                                      on: {
                                        onSetDate: function($event) {
                                          return _vm.setGameDate(
                                            $event,
                                            game,
                                            "start"
                                          )
                                        },
                                        onSetHours: function($event) {
                                          return _vm.setGameHours(
                                            $event,
                                            game,
                                            "start"
                                          )
                                        },
                                        onSetMinutes: function($event) {
                                          return _vm.setGameMinutes(
                                            $event,
                                            game,
                                            "start"
                                          )
                                        }
                                      }
                                    }),
                                    _c("TournamentDateTimePicker", {
                                      attrs: {
                                        type: "end",
                                        game: game,
                                        isBeingEdited: _vm.isBeingEdited
                                      },
                                      on: {
                                        onSetDate: function($event) {
                                          return _vm.setGameDate(
                                            $event,
                                            game,
                                            "end"
                                          )
                                        },
                                        onSetHours: function($event) {
                                          return _vm.setGameHours(
                                            $event,
                                            game,
                                            "end"
                                          )
                                        },
                                        onSetMinutes: function($event) {
                                          return _vm.setGameMinutes(
                                            $event,
                                            game,
                                            "end"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-text-field", {
                                  staticClass: "tournament-label",
                                  attrs: {
                                    box: "",
                                    label: "Label",
                                    disabled: !_vm.isBeingEdited,
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: game.label,
                                    callback: function($$v) {
                                      _vm.$set(game, "label", $$v)
                                    },
                                    expression: "game.label"
                                  }
                                }),
                                _c("v-select", {
                                  staticClass: "tournament-game",
                                  attrs: {
                                    items: _vm.getSelectGames(game.id),
                                    box: "",
                                    label: "Game",
                                    "item-value": "id",
                                    "item-text": "name",
                                    disabled:
                                      !_vm.isBeingEdited ||
                                      _vm.isRoundOver(game),
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: game.id,
                                    callback: function($$v) {
                                      _vm.$set(game, "id", $$v)
                                    },
                                    expression: "game.id"
                                  }
                                }),
                                _c("div", { staticClass: "round-info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "round-info-inner" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "Games ≈ " +
                                            _vm._s(_vm.getGamesCount(game))
                                        )
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "Teams ≈ " +
                                            _vm._s(
                                              _vm.approximation[index]
                                                .numberOfTeams
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm.isBeingEdited && !_vm.isRoundOver(game)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tournament-container__add-game-btn-container"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "tournament-container__add-game-btn",
                                                            attrs: {
                                                              circle: "",
                                                              small: "",
                                                              fab: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.addGameToRound(
                                                                  game.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("add")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_c("span", [_vm._v("+1 GAME")])]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _c(
                    "v-flex",
                    { attrs: { "xs-3": "" } },
                    [
                      !_vm.isBeingEdited
                        ? _c(
                            "v-btn",
                            {
                              attrs: { fab: "", small: "", depressed: "" },
                              on: { click: _vm.onEdit }
                            },
                            [_c("v-icon", [_vm._v("edit")])],
                            1
                          )
                        : [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  fab: "",
                                  color: "green",
                                  small: "",
                                  depressed: "",
                                  dark: ""
                                },
                                on: { click: _vm.onAddGame }
                              },
                              [_c("v-icon", [_vm._v("add")])],
                              1
                            ),
                            _vm.localTournament.canDeleteGame()
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      color: "red",
                                      dark: "",
                                      small: "",
                                      depressed: ""
                                    },
                                    on: { click: _vm.onDeleteGame }
                                  },
                                  [_c("v-icon", [_vm._v("delete")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasChanged && _vm.canSubmit
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", depressed: "" },
                                    on: { click: _vm.onSubmit }
                                  },
                                  [_vm._v("Submit")]
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "red",
                                  dark: "",
                                  depressed: ""
                                },
                                on: { click: _vm.onDelete }
                              },
                              [_vm._v("Delete")]
                            )
                          ]
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }