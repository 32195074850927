<template>
  <v-card v-if="localTheme" class="theme-customizer">
    <v-card-title primary-title>Theme Customizer</v-card-title>
    <v-card-text>
      <fieldset>
        <legend>Colors</legend>
        <StylesEditor
          :styles="localTheme.styles"
          @input="onStylesInput"
          class="mt-1"
        />
      </fieldset>
      <fieldset>
        <legend>Images</legend>
        <v-layout>
          <v-flex xs-3>
            <ThemeImage
              v-model="localTheme.images.logo"
              label="Top Left Image"
              @upload="onThemeImageUpload($event, 'logo')"
            />
          </v-flex>
          <v-flex xs-3>
            <ThemeImage
              v-model="localTheme.images.expoLogo"
              label="Expo Custom Logo"
              @upload="onThemeImageUpload($event, 'expoLogo')"
            />
          </v-flex>
          <v-flex xs-3>
            <ThemeImage
              v-model="localTheme.images.loginLogo"
              label="Login Logo"
              @upload="onThemeImageUpload($event, 'loginLogo')"
            />
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex
            v-for="(image, index) in localTheme.images.loginCarousel"
            :key="index"
            xs3
          >
            <ThemeImage
              v-model="localTheme.images.loginCarousel[index]"
              label="Login Page Image"
              @upload="onThemeImageUpload($event, 'loginCarousel', index)"
            />
          </v-flex>
        </v-layout>
      </fieldset>

      <fieldset>
        <legend>Meta</legend>
        <v-layout align-center>
          <v-flex xs2>
            <v-switch
              v-model="localTheme.meta.hideGoGame"
              label="Hide Go Game"
            />
          </v-flex>
          <v-flex xs2>
            <v-switch
              v-model="localTheme.meta.hideExpoText"
              label="Hide Expo Text"
            />
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="localTheme.meta.tagline"
              label="Tagline"
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>
      </fieldset>
    </v-card-text>

    <v-card-actions>
      <v-text-field
        v-model="localTheme.name"
        hide-details
        class="max-w-sm"
      ></v-text-field>
      <v-spacer />
      <v-btn color="red" @click="deleteTheme">Delete</v-btn>
      <v-btn color="primary" @click="onSave">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { cloneDeep } from "lodash"
import uniqid from "uniqid"

import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"

import { ThemeFactory } from "../entities/ThemeFactory"

import StylesEditor from "./StylesEditor.vue"
import ThemeImage from "./ThemeImage.vue"

/**
 * @typedef { import('./types').ThemeCustomizerTheme } ThemeCustomizerTheme
 */

export default Vue.extend({
  name: "ThemeCustomizer",
  components: {
    StylesEditor,
    ThemeImage
  },
  props: {
    theme: {
      /** @type {import('vue').PropType<ThemeCustomizerTheme>} */
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /** @type {(ThemeCustomizerTheme | null)} */
      localTheme: null
    }
  },
  watch: {
    theme: {
      handler(value) {
        this.localTheme = cloneDeep(value)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["token"])
  },
  methods: {
    onSave() {
      this.$emit("save", {
        themeID: this.localTheme.id,
        value: this.localTheme
      })
      this.localTheme = null
    },
    onCreate() {
      this.localTheme = ThemeFactory.create()
    },
    onStylesInput({ styles }) {
      this.localTheme.styles = styles
    },
    deleteTheme() {
      const themeID = this.localTheme?.id

      if (themeID) {
        this.$emit("delete", { themeID })
      }
      this.localTheme = null
    },
    /**
     * @param {string} dataUrl
     * @param {string} key
     * @param {number} [index]
     */
    async onThemeImageUpload(dataUrl, key, index) {
      try {
        const blob = await getBlobFromDataURL(dataUrl)
        const fileName = `gamephotos/${uniqid()}.png`
        const path = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })

        if (index !== undefined) {
          this.$set(this.localTheme.images[key], index, path)
        } else {
          this.$set(this.localTheme.images, key, path)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>
