<template>
  <div>
    <v-tooltip v-if="clientHasTheme" bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="primary"
          depressed
          :loading="isLoading"
          @click="copyClientTheme"
        >
          Copy Theme
        </v-btn>
      </template>
      <span>Copy the Client theme to all games. </span>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from "vue"

import { ActionTypes as ClientModuleActionTypes } from "@/store/ClientModule"

export default Vue.extend({
  name: "ClientSettingsActionsTabpanel",
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    clientHasTheme() {
      return this.$store.state.auth.client.themeID !== undefined
    }
  },
  methods: {
    copyClientTheme() {
      this.isLoading = true

      this.$store
        .dispatch(ClientModuleActionTypes.APPLY_CLINET_THEME_TO_GAMES, {
          games: this.$store.state.pregame.games
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
})
</script>
